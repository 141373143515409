import React, { useState, useRef } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { get, map } from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import loadImage from 'blueimp-load-image';

import useMutation from '../../hooks/useMutation';
import { WRITE_MESSAGE } from '../../graphql/mutations/message';
// import { getNow } from '../../utils/date-format';
import { LoadingPureSpinner } from '../../components/loading';
import trackingException from '../../hooks/useTrackingException';
import upload from '../../utils/upload';

const MessageTyping = ({
  brand, receiver, slug, serverOffset, isScrollDown, setAfterUploadImage,
}) => {
  const inputImageRef = useRef(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [writeMessageMutation] = useMutation(WRITE_MESSAGE);
  // const userSlug = useStoreState((state) => state.user.user.userSlug);
  const setMyMessageItems = useStoreActions((actions) => actions.message.setMyMessageItems);
  const openToast = useStoreActions((actions) => actions.toast.openToast);
  const setAppLoading = useStoreActions((actions) => actions.app.setAppLoading);

  const submit = async () => {
    if (loading) return;
    if (!message) return;
    setLoading(true);
    try {
      const {
        data: { writeMessage },
      } = await writeMessageMutation({
        variables: {
          receiver: {
            slugUrl: receiver.slugUrl,
            type: receiver.type,
          },
          form: {
            description: message,
          },
          brandId: +brand,
        },
        refetchQueries: ['getConversations'],
      });
      if (writeMessage.successful) {
        setMyMessageItems({
          conversationSlug: writeMessage.conversationSlug,
          message: { ...writeMessage.message, senderType: 'user' },
        });
        setLoading(false);
      } else {
        openToast(writeMessage.errors[0].message);
        setLoading(false);
      }
    } catch (err) {
      trackingException({ name: 'MessageTyping.submit', message: err.message, meta: { err: JSON.stringify(err, Object.getOwnPropertyNames(err)) } });
      console.log('fail comment');
      openToast(err.message);
      setLoading(false);
    }
    setMessage('');
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      submit(e);
    }
  };

  const uploadImg = async (images) => {
    try {
      const promises = map(images, async (image) => {
        const result = await upload(image.file);
        return new Promise((resolve) => {
          const imgPath = get(result, 'data.result.url');
          const width = get(result, 'data.result.width');
          const height = get(result, 'data.result.height');

          return resolve({
            ...image, imgPath, width, height,
          });
        });
      });

      const objImages = await Promise.all(promises);

      const {
        data: { writeMessage },
      } = await writeMessageMutation({
        variables: {
          receiver: {
            slugUrl: receiver.slugUrl,
            type: receiver.type,
          },
          form: {
            description: '',
            images: map(objImages, (image) => ({
              imgPath: image.imgPath,
              width: image.width,
              height: image.height,
            })),
          },
          brandId: +brand,
        },
        refetchQueries: ['getConversations'],
      });

      if (writeMessage.successful) {
        setMyMessageItems({
          conversationSlug: writeMessage.conversationSlug,
          message: { ...writeMessage.message, senderType: 'user' },
        });
        setLoading(false);
        setAppLoading(false);
        window.scrollTo(0, 0);
        isScrollDown(false);
        setAfterUploadImage(true);
      } else {
        openToast(writeMessage.errors[0].message);
        setLoading(false);
        setAppLoading(false);
      }
    } catch (err) {
      openToast({ message: err.message, type: 'error' });
      setLoading(false);
      setAppLoading(false);
    }
  };

  const onFileChange = async ({ target: { files } }) => {
    setLoading(true);
    // setAppLoading(true);
    try {
      const promises = map(files, async (file) => {
        return new Promise((resolve) => {
          loadImage.parseMetaData(file, (data) => {
            const loadImageOptions = { canvas: true };
            if (data.exif && data.exif.get('Orientation')) {
              loadImageOptions.orientation = data.exif.get('Orientation');
            }
            const callBack = (canvas) => resolve({
              preview: canvas.toDataURL(file.type),
              file,
              filename: file.name,
              size: file.size,
              filetype: file.type,
            });
            loadImage(file, callBack, loadImageOptions);
          });
        });
      });
      const images = await Promise.all(promises);
      uploadImg(images);
    } catch (err) {
      openToast({ message: err.message, type: 'error' });
      setLoading(false);
      // setAppLoading(false);
    }
  };

  return (
    <div className="msg-typing">
      <textarea
        type="text"
        placeholder="พิมพ์ข้อความของคุณ ..."
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={onEnterPress}
        value={message}
      />
      <div className="btn-wrapper">

        <div className="btn-wrapper-img">
          <div className="btn no-bg">
            <input
              ref={inputImageRef}
              type="file"
              name="img"
              accept="image/x-png,image/jpeg"
              onChange={onFileChange}
              onClick={(e) => { e.target.value = null; }}
              style={{ display: 'none' }}
            />
            <img
              onClick={() => {
                if (inputImageRef) {
                  inputImageRef.current.click();
                }
              }}
              src="https://static.salehere.co.th/static/images/ico-upload-img.svg"
              alt="IMG"
            />
          </div>
        </div>
        <div className="btn no-bg" onClick={submit}>
          {loading ? <LoadingPureSpinner /> : <i className="fa fa-paper-plane" aria-hidden="true" />}
        </div>
      </div>
    </div>
  );
};

export default MessageTyping;
