import axios from 'axios';
import { print } from 'graphql';
import { graphQLEndpoint } from '../config';
import { LINE_SIGNIN } from '../graphql/mutations/auth';

export const getProfile = async (token) => {
  try {
    const {
      data: {
        data: { myProfile },
      },
    } = await axios({
      url: graphQLEndpoint,
      method: 'post',
      headers: {
        authorization: token,
      },
      data: {
        query: `
            {
              myProfile {
                id
                email
                displayName
                profileImage {
                  imgPath
                }
                myBusinesses {
                  id
                }
                isFirstTime
                isActive
                gender
                dob
                birthday {
                  iso
                }
                tel
                isActiveBySMS
                userSlug
                statistic {
                  numberOfUnreadNotifications
                  numberOfFollowedBrands
                  numberOfSaves
                  numberOfUnreadMessages
                  numberOfMyCoupons
                }
              }
            }
          `,
      },
    });
    return myProfile;
  } catch (err) {
    return null;
  }
};

export const lineSignInMutation = async (token) => {
  try {
    const {
      data: {
        data: { lineLiffSignIn },
      },
    } = await axios.post(graphQLEndpoint, {
      query: print(LINE_SIGNIN),
      variables: { lineToken: token },
    });
    return lineLiffSignIn;
  } catch (err) {
    return null;
  }
};
