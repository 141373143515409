import { gql } from 'apollo-boost';

export const FOLLOW = gql`
  mutation follow($id: Int!, $type: FollowType!) {
    follow(id: $id, type: $type) {
      successful
      errors {
        message
        path
      }
    }
  }
`;

export const UN_FOLLOW = gql`
  mutation unfollow($id: Int!, $type: FollowType!) {
    unfollow(id: $id, type: $type) {
      successful
      errors {
        message
        path
      }
    }
  }
`;
