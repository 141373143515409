import moment from 'moment';
import 'moment/locale/th';
import { curry } from 'lodash';

window.moment = moment;
moment().locale('th');
export const fromNow = curry((language, dateString) => {
  console.log('dateString: ', dateString);
  return moment(dateString)
    .utcOffset('+07:00')
    .locale(language || 'th')
    .subtract(20, 's')
    .fromNow();
});

export const fullDate = curry((language, dateString) => {
  const date = moment(dateString)
    .utcOffset('+07:00')
    .locale(language);
  const addYears = 543;
  return `${date.format('DD')} ${date.format('MMMM')} ${String(
    Number(parseInt(date.format('YYYY'), 10) + addYears),
  )}`;
});

export const shortenDate = curry((language, dateString) => {
  const date = moment(dateString)
    .utcOffset('+07:00')
    .locale(language);
  const addYears = 543;
  return `${date.format('DD')} ${date.format('MMM')} ${String(
    Number(parseInt(date.format('YYYY'), 10) + addYears),
  ).substring(2, 4)}`;
});

export const shortenTime = curry((language, dateString) => {
  const date = moment(dateString)
    .utcOffset('+07:00')
    .locale(language);
  if (language === 'th') return `${date.format('HH:mm')} น.`;
  return `${date.format('HH:mm')}`;
});

export const formatDOB = curry((language, dateString) => {
  const date = moment(dateString)
    .utcOffset('+07:00')
    .locale(language);
  return `${date.format('YYYY')}-${date.format('MM')}-${date.format('DD')}`;
});

export const isComingSoon = curry((beginDate) => {
  return Number(beginDate) > moment().valueOf();
});

export const toUnix = curry((language, dateString) => {
  const date = moment(dateString)
    .utcOffset('+07:00')
    .locale(language);
  return date.valueOf();
});

export const getNow = (serverOffset) => {
  const date = moment().add('milliseconds', serverOffset);
  return date.format();
};

export const getFullMonth = () => {
  const date = moment()
    .utcOffset('+07:00')
    .locale('th');
  return date.format('MMMM');
};

export const getFullYear = () => {
  const date = moment()
    .utcOffset('+07:00')
    .locale('th');
  return date.format('YYYY');
};

export const dateWithTimeZone = curry((language, dateString) => {
  const date = moment(dateString)
    .utcOffset('+07:00')
    .locale(language);
  return `${date.format('YYYY-MM-DDTHH:mm:ss+07:00')}`;
});
