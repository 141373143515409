import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

const Container = styled.div.attrs(() => ({
  className: 'noti-bar',
}))`
  bottom: ${({ isOpen }) => (isOpen ? '0px' : '-70px')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out 0s;
`;

const Toast = () => {
  const isOpen = useStoreState((state) => state.toast.isOpen);
  const message = useStoreState((state) => state.toast.message);
  return (
    <Container isOpen={isOpen}>
      <p>{message}</p>
    </Container>
  );
};

export default Toast;
