import { useEffect, useState } from 'react';

const useCountdown = (initialTimeLeft) => {
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((currentTimeLeft) => {
        if (currentTimeLeft < 1) {
          clearInterval(interval);
          return 0;
        }

        return currentTimeLeft - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLeft]);
  return { timeLeft, setTimeLeft };
};

export default useCountdown;
