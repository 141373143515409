import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { get } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CloseSvg from '../../svg/close';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const CouponUsedModal = ({ coupon }) => {
  const [opacity, setOpacity] = useState(1);
  const [width, setWidth] = useState(null);
  const [imageElement, setImageElement] = useState(
    'https://static.salehere.co.th/static/images/ico-used-coupon.png',
  );
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(500, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  useEffect(() => {
    if (get(coupon, 'brand.seoSlugUrl', '') === 'mister-donut') {
      setTimeout(() => {
        setOpacity(0);
        setTimeout(() => {
          setWidth(180);
          setImageElement('https://static.salehere.co.th/static/images/1583897927234.jpg');
          setOpacity(1);
        }, 300);
      }, 2500);
    }
  }, []);
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick={false}
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div className="modal-dialog modal-md coupon" style={{ margin: '0px', width: '100%' }}>
          <div className="modal-content">
            <div className="modal-header">
              <div className="close-btn" onClick={closeModal}>
                <CloseSvg />
              </div>
              <img src="https://static.salehere.co.th/static/images/bg-used-coupon.jpg" alt="" />
            </div>
            <div className="modal-body">
              <div className="ico">
                <img
                  className="fadeout-in-3s"
                  src={imageElement}
                  alt=""
                  style={{
                    transition: 'all .3s ease',
                    opacity,
                    width: width || '100%',
                  }}
                />
              </div>
              <h3>ใช้คูปองเรียบร้อย</h3>
              <p>{get(coupon, 'title', '')}</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: 'gray',
                }}
              >
                <span>
                  {`ใช้แล้วเมื่อ ${get(coupon, 'redeemedTime.shortDateTime', '').replace(
                    'เมื่อ',
                    'เวลา',
                  )}`}
                </span>
                <span style={{ fontSize: '.8rem', marginTop: '3px' }}>
                  {`สาขา ${get(coupon, 'redeemedAt', '')}`}
                </span>
              </div>
              <div className="line-container" style={{ marginTop: '10px' }}>
                <div className="line" />
              </div>
            </div>
            <div className="modal-footer" onClick={closeModal}>
              กลับ
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CouponUsedModal;
