/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { map, get, take } from 'lodash';
import { css } from '@emotion/core';
import { useHistory } from 'react-router-dom';

import SyncLoader from 'react-spinners/SyncLoader';
import { SET_LINE_GROUP_ID } from '../../graphql/mutations/line';
import { GET_BRAND_BY_LINE_GROUP_ID } from '../../graphql/queries/brand';
import useMutation from '../../hooks/useMutation';
import useQuery from '../../hooks/useQuery';
import ArrowLeft from '../../components/svg/arrow-left';
import ArrowRight from '../../components/svg/arrow-right';
import config from '../../config';

const BackGroundContainer = styled.div`
  background-color: #ed1c24;
  height: 100%;
`;

const MAX_NUMBER = 6;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #000000ab;
  z-index: 10;
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #fff;
  margin-top: 10px;
  font-size: 1rem;
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const InitialShoppingBot = () => {
  const history = useHistory();
  const [isClaim, setIsClaim] = useState(false);
  const [setLineGroupIdMutation] = useMutation(SET_LINE_GROUP_ID);
  const [pinCode, setPinCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const groupId = get(history.location.search.split('&groupId='), '[1]');

  const { data: brandData, loading: loadingBrandData, refetch } = useQuery(
    GET_BRAND_BY_LINE_GROUP_ID,
    {
      variables: { groupId },
      skip: !groupId,
      fetchPolicy: 'network-only',
      context: {
        headers: {
          authorization: config.env === 'production' ? 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxMjk1NDgsImZpcnN0QWN0aXZlQXQiOiIyMDIxLTAxLTI5VDEzOjM5OjQ4LjU5OFoiLCJlbnYiOiJwcm9kdWN0aW9uIiwiZm9yIjoibGluZSJ9LCJpYXQiOjE2MTcwOTQwODcsImV4cCI6MTY2ODM5MDA4N30.Iiso0cxIIxlwi7O8Qo-blZ4c6Z1QBniFmWMzEQgU52IjZgPLOjQRifdME5l8AnztD8aFGkd0Pla0xqaGQQIvntesI8DuAbeFZjssGCZRbruVk7zDUYQUGCsIS3pMRWlbJOjPLV_VrGsY7NSVDQxn801vilhBHSwH61MzXdTXo8AmmgTRL8OzOE4vo3pyOgjX1wHPafD7yRLGysZ-dMMplVuTFdEETT7AASvMk0Lmnudrj84axVHjglhJVbKRn4wLyxTgLQkiUyq71oxg22AbTPjHXfvVd7cSydngol2rSsaXTQ498ypjlWHl6r2qyIFas_92HdJl9s6vmINEk5qN3w' : undefined,
        },
      },
    },
  );
  console.log('brandData: ', brandData);
  useEffect(() => {
    console.log('loadingBrandData: ', loadingBrandData);
    console.log('brandData: ', brandData);
    setLoading(true);
    if (!loadingBrandData && brandData && brandData.brandByGroupId) {
      setIsClaim(true);
    }
    setLoading(false);
  }, [loadingBrandData, brandData]);
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
  }, []);
  const onPressNumber = (n) => {
    setErrorMessage('');
    if (pinCode.length < MAX_NUMBER) {
      setPinCode([...pinCode, n]);
    }
  };
  const onDel = () => {
    setErrorMessage('');
    setPinCode(take(pinCode, pinCode.length - 1));
  };

  const callApi = async () => {
    setLoading(true);

    if (!groupId) {
      setErrorMessage('ไม่สามารถทำรายการได้');
      setLoading(false);
      return;
    }
    const { data } = await setLineGroupIdMutation({
      variables: {
        brandId: Number(pinCode.join('')),
        groupId,
      },
      context: {
        headers: {
          authorization: config.env === 'production' ? 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxMjk1NDgsImZpcnN0QWN0aXZlQXQiOiIyMDIxLTAxLTI5VDEzOjM5OjQ4LjU5OFoiLCJlbnYiOiJwcm9kdWN0aW9uIiwiZm9yIjoibGluZSJ9LCJpYXQiOjE2MTcwOTQwODcsImV4cCI6MTY2ODM5MDA4N30.Iiso0cxIIxlwi7O8Qo-blZ4c6Z1QBniFmWMzEQgU52IjZgPLOjQRifdME5l8AnztD8aFGkd0Pla0xqaGQQIvntesI8DuAbeFZjssGCZRbruVk7zDUYQUGCsIS3pMRWlbJOjPLV_VrGsY7NSVDQxn801vilhBHSwH61MzXdTXo8AmmgTRL8OzOE4vo3pyOgjX1wHPafD7yRLGysZ-dMMplVuTFdEETT7AASvMk0Lmnudrj84axVHjglhJVbKRn4wLyxTgLQkiUyq71oxg22AbTPjHXfvVd7cSydngol2rSsaXTQ498ypjlWHl6r2qyIFas_92HdJl9s6vmINEk5qN3w' : undefined,
        },
      },
    });
    if (data.setLineGroupId.successful) {
      refetch();
      // liff.closeWindow();
    } else {
      setErrorMessage(data.setLineGroupId.errors[0].message);
      setPinCode([]);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (pinCode.length === MAX_NUMBER) {
      callApi();
    }
  }, [pinCode]);
  return (
    <BackGroundContainer>
      {loading && (
        <LoadingContainer>
          <SyncLoader css={override} size={15} color={'#ffffff'} loading={loading} />
        </LoadingContainer>
      )}
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />

      <div className="shopping-bot-main">
        {isClaim ? (
          <div className="claimed">
            <h2>เปิดใช้งาน Bot เรียบร้อยแล้ว</h2>
            <div className="link-container d-flex align-items-center">
              <img src={brandData.brandByGroupId.logoImg.imgPath} alt="" className="brand-logo" />
              <div className="connector d-flex">
                <ArrowLeft />
                <ArrowRight />
              </div>
              <img
                src="https://img.proxumer.com/p/1200x0/2020/10/12/ewtg3wkb1zlz.jpg"
                alt=""
                className="bot-logo"
              />
            </div>
            <div className="rule">
              <h3>สามารถแจ้งเตือนเมื่อ</h3>
              <ul>
                <li>เมื่อมีรายการสั่งซื้อใหม่</li>
                <li>เมื่อมีข้อความใหม่</li>
              </ul>
            </div>
            <div className="btn" onClick={() => liff.closeWindow()}>
              ปิด
            </div>
          </div>
        ) : (
          <>
            <motion.div
              className="container shopping-bot"
              initial="initial"
              animate="animate"
              exit={{ opacity: 0 }}
            >
              <div className="title">
                <div className="img">
                  <img src="https://img.proxumer.com/p/1200x0/2020/10/09/9dz6hxbhgysf.jpg" alt="" />
                </div>
                <h2>กรอกรหัสเริ่มต้นใช้งาน</h2>
              </div>
              <div className="pin-input-container">
                {map(Array.from(Array(MAX_NUMBER).keys()), (v, k) => {
                  return (
                    <div className="pin-input" key={k}>
                      <span>{pinCode[k]}</span>
                    </div>
                  );
                })}
              </div>
            </motion.div>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <div className="pin-view row">
              <div className="col-4 pin" onClick={() => onPressNumber(1)}>
                1
              </div>
              <div className="col-4 pin" onClick={() => onPressNumber(2)}>
                2
              </div>
              <div className="col-4 pin" onClick={() => onPressNumber(3)}>
                3
              </div>
              <div className="col-4 pin" onClick={() => onPressNumber(4)}>
                4
              </div>
              <div className="col-4 pin" onClick={() => onPressNumber(5)}>
                5
              </div>
              <div className="col-4 pin" onClick={() => onPressNumber(6)}>
                6
              </div>
              <div className="col-4 pin" onClick={() => onPressNumber(7)}>
                7
              </div>
              <div className="col-4 pin" onClick={() => onPressNumber(8)}>
                8
              </div>
              <div className="col-4 pin" onClick={() => onPressNumber(9)}>
                9
              </div>
              <div className="col-4 pin" />
              <div className="col-4 pin" onClick={() => onPressNumber(0)}>
                0
              </div>
              <div className="col-4 pin" onClick={() => onDel()}>
                ลบ
              </div>
            </div>
          </>
        )}
      </div>
    </BackGroundContainer>
  );
};

export default InitialShoppingBot;
