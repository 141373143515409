import { action } from 'easy-peasy';
import { map, find } from 'lodash';

export default {
  brands: [],
  followBrand: action((state, payload) => {
    const existsBrand = find(state.brands, { id: payload.id });
    if (existsBrand) {
      state.brands = map(state.brands, (brand) => {
        if (brand.id === payload.id) {
          return {
            ...brand,
            isInteract: true,
            count: brand.count + 1,
          };
        }
        return brand;
      });
    } else {
      state.brands = [...state.brands, payload];
    }
  }),
  unFollowBrand: action((state, payload) => {
    const existsBrand = find(state.brands, { id: payload.id });
    if (existsBrand) {
      state.brands = map(state.brands, (brand) => {
        if (brand.id === payload.id) {
          return {
            ...brand,
            isInteract: false,
            count: brand.count - 1,
          };
        }
        return brand;
      });
    } else {
      state.brands = [...state.brands, payload];
    }
  }),
};
