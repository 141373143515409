import { useMutation } from '@apollo/react-hooks';

const useMutationWithCatcher = (mutation, options) => {
  const [executeMutation, responseData] = useMutation(mutation, options);
  if (responseData.error) {
    // send error to sentry or something
  }
  return [executeMutation, responseData];
};

export default useMutationWithCatcher;
