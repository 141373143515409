import React from 'react';
import Modal from 'react-modal';
import { get } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FollowButton from '../../buttons/follow-button';
import useFollowBrand from '../../../hooks/useFollowBrand';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const CampaignFollowBrandModal = ({ brandCampaign, isGetReward }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);
  const openModal = useStoreActions((dispatch) => dispatch.modal.openModal);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(400, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  const { isInteract } = useFollowBrand({
    objId: get(brandCampaign, 'brand.id', 0),
    isInteract: get(brandCampaign, 'brand.status.follow.isInteract', false),
  });
  const brand = get(brandCampaign, 'brand', {});
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div
          className="modal-dialog modal-md coupon un brand-follow"
          style={{ margin: '0px', width: '100%' }}
        >
          <div className="modal-content" style={{ overflow: 'visible' }}>
            <div className="modal-header">
              <img
                src={get(brand, 'coverImages[0].imgMediumPath', '')}
                alt=""
                className="brand-cover"
                style={{ borderTopRightRadius: '7.5px', borderTopLeftRadius: '7.5px' }}
              />
              <div
                className="brand-cover-overlay"
                style={{ borderTopRightRadius: '7.5px', borderTopLeftRadius: '7.5px' }}
              />
              <div className="brand-logo">
                <img src={get(brand, 'logoImg.imgMediumPath', '')} alt="" />
              </div>
            </div>
            <div className="modal-body">
              <h3>อย่าลืม!</h3>
              <h4>
                กดติดตาม
                {' '}
                <span>{get(brand, 'name', '')}</span>
                {` เพื่อ${isGetReward ? 'รับรางวัล' : 'ร่วม'}กิจกรรม`}
              </h4>
              <FollowButton
                objId={get(brand, 'id', 0)}
                followStatus={get(brand, 'status.follow', {})}
                className="follow-button btn border curve on-card d-lg-block"
              />
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div
              className={`modal-footer ${isInteract ? '' : 'disabled'} ${
                isGetReward && isInteract ? 'get-reward' : ''
              }`}
              style={{ borderBottomRightRadius: '7.5px', borderBottomLeftRadius: '7.5px' }}
              onClick={() => {
                if (isInteract) {
                  if (isGetReward) {
                    openModal({
                      type: 'GET_REWARD',
                      data: { brandCampaignId: get(brandCampaign, 'id', 0) },
                    });
                  } else {
                    openModal({
                      type: 'COMMENT_CAMPAIGN',
                      data: {
                        campaignId: brandCampaign.id,
                        status: brandCampaign.status,
                        options: { isRequiredImage: get(brandCampaign, 'isRequiredImage') },
                      },
                    });
                  }
                } else {
                  return null;
                }
              }}
            >
              <a
                href={'#'}
                onClick={(e) => {
                  e.preventDefault();
                  if (isInteract) {
                    if (isGetReward) {
                      openModal({
                        type: 'GET_REWARD',
                        data: { brandCampaignId: get(brandCampaign, 'id', 0) },
                      });
                    } else {
                      openModal({
                        type: 'COMMENT_CAMPAIGN',
                        data: {
                          campaignId: brandCampaign.id,
                          status: brandCampaign.status,
                          options: { isRequiredImage: get(brandCampaign, 'isRequiredImage') },
                        },
                      });
                    }
                  } else {
                    return null;
                  }
                }}
              >
                {isGetReward ? 'รับรางวัล' : 'ร่วมกิจกรรม'}
              </a>
            </div>
          </div>
          <div />
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CampaignFollowBrandModal;
