import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { map, get } from 'lodash';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import CampaignCard from '../../components/cards/campaign-card';
import Header from '../../components/header';
import { LoadingPureSpinner } from '../../components/loading';
import useQuery from '../../hooks/useQuery';
import { GET_CAMPAIGNS } from '../../graphql/queries/campaign';

const Campaigns = () => {
  const { data, loading, loadMore } = useQuery(GET_CAMPAIGNS, {
    variables: {
      limit: 4,
      offset: 0,
    },
    fetchPolicy: 'network-only',
  });
  console.log('loading: ', loading);
  console.log('data: ', data);
  const [loadingMore, setLoadingMore] = useState(false);
  const [HASMORE, setHasMore] = useState(true);
  const handleLoadMore = () => {
    setLoadingMore(true);
    loadMore({
      pathOfLoadmoreableItem: 'brandCampaigns',
      limit: 9,
      onDone: ({ hasMore }) => {
        setHasMore(hasMore);
        setLoadingMore(false);
      },
    });
  };
  const infiniteRef = useInfiniteScroll({
    loading: loadingMore,
    hasNextPage: HASMORE,
    onLoadMore: handleLoadMore,
    scrollContainer: 'window',
  });
  return (
    <motion.div initial="initial" animate="animate" exit={{ opacity: 0 }}>
      <Header title="กิจกรรมทั้งหมด" />
      <div className="container">
        <div className="row" ref={infiniteRef}>
          {loading ? (
            <LoadingPureSpinner
              fullWidth
              size={40}
              borderSize={'3px'}
              style={{ marginTop: '20%' }}
            />
          ) : (
            <>
              {map(get(data, 'brandCampaigns', []), (campaign) => {
                return <CampaignCard key={`campaign-${campaign.id}`} {...campaign} />;
              })}
              {loadingMore && (
                <div className="col-12" style={{ marginBottom: '20px' }}>
                  <LoadingPureSpinner />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default Campaigns;
