/* eslint-disable no-undef */
import VConsole from 'vconsole';
import React, { useEffect } from 'react';
import Script from 'react-load-script';
import { ApolloProvider as ApolloProviderHook } from '@apollo/react-hooks';
import { StoreProvider } from 'easy-peasy';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AnimatePresence } from 'framer-motion';
import JsonWebToken from 'jsonwebtoken';
import axios from 'axios';
import { v1 as uuidv1 } from 'uuid';

import initialApollo from './connectors/apollo';
import GlobalStyles from './global-styles';
import SplashScreen from './components/splashscreen';
// import NavigationBar from "./components/navigation-bar";
import Campaigns from './containers/campaigns';
import Coupons from './containers/coupons';
import CouponInfo from './containers/coupon-info';
import CampaignInfo from './containers/campaign-info';
import MyPromotions from './containers/mypromotions';
import NearBy from './containers/nearby';
import PickCategory from './containers/pick-category';
import PickBrand from './containers/pick-brand';
import Chat from './containers/chat';
import InitialShoppingBot from './containers/initial-shopping-bot';
// import Payment from './containers/payment';
import trackingException from './hooks/useTrackingException';

import MessageSubscription from './components/message/subscription';
import ModalRoot from './components/modals';
import ModalOverlay from './components/modals-overlay';
import ToastRoot from './components/toast';
import store from './store';
import { lineSignInMutation } from './utils/api';
import {
  storageKey, env, liffId, publicKey,
} from './config';

const theme = {
  color: {
    primary: '#ed1c24',
  },
  font: {
    common: 'Prompt',
    content: 'Sarabun',
  },
  colors: {
    red: '#ed1c24',
    blue: '#1f255a',
    white: '#000000',
  },
};

window.store = store;

const history = createBrowserHistory();
// const transactionId = uuidv1();
// const debugUrl = 'https://ce76-2001-fb1-14-43b4-20ea-2acb-52fe-cb98.ngrok.io/debug';
function App() {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (env === 'development') {
      new VConsole();
      window.store.dispatch.user.setUser(localStorage.getItem(storageKey));
    }
    localStorage.clear();
    // liff.logout();
    // new VConsole();
    // axios.post(debugUrl, { message: 'start transaction', transactionId, uri: window.location.href });
  }, []);
  return (
    <StoreProvider store={store}>
      <ApolloProviderHook client={initialApollo({})}>
        <ThemeProvider theme={theme}>
          <Script
            url="https://static.line-scdn.net/liff/edge/2.1/sdk.js"
            onLoad={async () => {
              console.log('loaded', window.location);
              if (window.location.pathname.includes('/initial-shopping-bot')) {
                return;
              }
              store.dispatch.app.setAppLoading(true);
              try {
                await liff.init({
                  liffId,
                });
                if (liff.isLoggedIn()) {
                  // axios.post(debugUrl, { message: 'isLoggedIn', transactionId, uri: window.location.href });
                  // localStorage.removeItem(storageKey);
                  let requiredLogin = false;
                  const existToken = localStorage.getItem(storageKey);
                  if (existToken) {
                    // axios.post(debugUrl, { message: 'existToken', transactionId, uri: window.location.href });
                    trackingException({
                      name: 'liff.isLoggedIn.existToken',
                      meta: { existToken },
                    });
                    try {
                      const { exp, user } = await JsonWebToken.verify(existToken, publicKey, {
                        algorithms: 'RS256',
                      });
                      if (!user) {
                        localStorage.clear();
                        window.location.reload();
                        return;
                      }
                      console.log('exp: ', exp);
                      if (Date.now() / 1000 > exp) {
                        requiredLogin = true;
                      } else {
                        window.store.dispatch.user.setUser(existToken);
                      }
                    } catch (err) {
                      trackingException({
                        name: 'liff.isLoggedIn.catch',
                        message: err.message,
                      });
                      requiredLogin = true;
                    }
                  } else {
                    // axios.post(debugUrl, { message: 'nnot existToken', transactionId, uri: window.location.href });
                    requiredLogin = true;
                  }
                  console.log('requiredLogin: ', requiredLogin);
                  if (requiredLogin) {
                    // axios.post(debugUrl, { message: 'require login', transactionId, uri: window.location.href });
                    // send mutation login
                    const accessToken = await liff.getAccessToken();
                    console.log('accessToken: ', accessToken);
                    const loginResponse = await lineSignInMutation(accessToken);

                    // console.log('loginResponse: ', JSON.stringify(loginResponse));
                    if (loginResponse.successful) {
                      await localStorage.setItem(storageKey, loginResponse.token);
                      window.store.dispatch.user.setUser(loginResponse.token);
                    }
                  }
                  const redirectUri = localStorage.getItem('redirectUri');
                  if (redirectUri) {
                    window.location.href = redirectUri;
                    localStorage.removeItem('redirectUri');
                  }
                } else {
                  // axios.post(debugUrl, { message: 'not line login', transactionId, uri: window.location.href });
                  localStorage.setItem('redirectUri', window.location.href);
                  liff.login();
                }
              } catch (err) {
                console.log('err: ', err);
              }
              store.dispatch.app.setAppLoading(false);
            }}
          />
          <GlobalStyles />
          <SplashScreen />
          <ModalRoot />
          <ModalOverlay />
          <ToastRoot />
          <MessageSubscription />
          <Router history={history}>
            <Route
              render={() => (
                <AnimatePresence exitBeforeEnter>
                  <Switch>
                    <Route exact path="/campaigns" component={Campaigns} />
                    <Route exact path="/coupons" component={Coupons} />
                    <Route exact path="/brand/:brandSlug/coupons/:slug" component={CouponInfo} />
                    <Route
                      exact
                      path="/brand/:brandSlug/campaigns/:slug"
                      component={CampaignInfo}
                    />
                    <Route exact path="/mypromotions" component={MyPromotions} />
                    <Route exact path="/nearby" component={NearBy} />
                    <Route exact path="/pick-category" component={PickCategory} />
                    <Route exact path="/pick-brand" component={PickBrand} />
                    <Route exact path="/chat" component={Chat} />
                    <Route exact path="/initial-shopping-bot" component={InitialShoppingBot} />
                    {/* <Route exact path="/payment" component={Payment} /> */}
                  </Switch>
                </AnimatePresence>
              )}
            />
          </Router>
          {/* <NavigationBar /> */}
        </ThemeProvider>
      </ApolloProviderHook>
    </StoreProvider>
  );
}

export default App;
