import React from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { map, get } from 'lodash';

import CouponCard from '../../components/cards/coupon-card';
import Header from '../../components/header';
import useQuery from '../../hooks/useQuery';
import { GET_COUPONS } from '../../graphql/queries/coupon';

const Coupons = () => {
  const {
    data, loading, loadMore, refetch, networkStatus,
  } = useQuery(GET_COUPONS, {
    variables: {
      limit: 15,
      offset: 0,
      filter: undefined,
      // filter: cate === 'all' ? undefined : { categorySlug: cate },
    },
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'network-only',
  });
  console.log('loading: ', loading);
  console.log('data: ', data);
  const history = useHistory();
  return (
    <motion.div initial="initial" animate="animate" exit={{ opacity: 0 }}>
      <Header title="คูปองทั้งหมด" />
      <div className="container coupon">
        <div className="landing-coupon row">
          {loading
            ? map([0, 1, 2, 3, 4, 5], (index) => {
              return <CouponCard key={`coupon-loading-${index}`} loading />;
            })
            : map(get(data, 'coupons', []), (coupon, index) => {
              return <CouponCard key={`coupon-${coupon.id}`} {...coupon} big={index === 1} />;
            })}
        </div>
      </div>
    </motion.div>
  );
};

export default Coupons;
