import React from 'react';
import { useStoreState } from 'easy-peasy';
import CouponConfirmModal from './coupon/confirm';
import CouponFollowBrandModal from './coupon/follow-brand';
import CouponEnterPhoneNoModal from './coupon/enter-phone-no';
import CouponVerifyPhoneNoModal from './coupon/verify-phone-no';
import CouponClaimedModal from './coupon/claimed';
import CouponUsedModal from './coupon/used';
import CouponUsedChoicesModal from './coupon/used-chioces';
import CouponBranchesModal from './coupon/branches';
import CouponNotSupportModal from './coupon/not-support';
import CouponWarningModal from './coupon/warning';
import CouponQrReaderModal from './coupon/qr';
import GetRewardModal from './campaign/get-reward';
import CampaignFollowBrandModal from './campaign/follow-brand';
import SuccessJoinCampaignModal from './campaign/success-join';
import CommentCampaignModal from './campaign/comment';
import ChoicesModal from './choices-modal';
import ShowImageModal from './chat/image';

const MODAL_COMPONENTS = {
  COUPON_FOLLOW_BRAND: CouponFollowBrandModal,
  COUPON_ENTER_PHONE_NO: CouponEnterPhoneNoModal,
  COUPON_VERIFY_PHONE_NO: CouponVerifyPhoneNoModal,
  COUPON_CLAIMED: CouponClaimedModal,
  COUPON_USED: CouponUsedModal,
  COUPON_USED_CHOICES: CouponUsedChoicesModal,
  COUPON_NOT_SUPPORT: CouponNotSupportModal,
  COUPON_WARNING: CouponWarningModal,
  COUPON_BRANCHES: CouponBranchesModal,
  COUPON_CONFIRM: CouponConfirmModal,
  QR_READER: CouponQrReaderModal,
  COMMENT_CAMPAIGN: CommentCampaignModal,
  GET_REWARD: GetRewardModal,
  CAMPAIGN_FOLLOW_BRAND: CampaignFollowBrandModal,
  SUCCESS_JOIN_CAMPAIGN: SuccessJoinCampaignModal,
  CHOICES: ChoicesModal,
  SHOW_IMAGE: ShowImageModal,
};

const ModalRoot = () => {
  const { modalType, modalProps } = useStoreState((state) => state.modal);
  if (!modalType) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[modalType];
  if (ModalComponent) return <ModalComponent {...modalProps} />;
  return null;
};

export default ModalRoot;
