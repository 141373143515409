/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  isEmpty, map, get, find, filter, partialRight, pick,
} from 'lodash';

import useQuery from '../../hooks/useQuery';
import useMutation from '../../hooks/useMutation';
import { SUGGEST_FOLLOWING } from '../../graphql/queries/user';
import { SAVE_FOLLOWING_BRANDS } from '../../graphql/mutations/user';

const PickBrand = (props) => {
  const [sending, setSending] = useState(false);
  const [pickedBrands, setPickedBrands] = useState([]);
  const { data } = useQuery(SUGGEST_FOLLOWING, {
    variables: {
      filter: {
        categories: map(props.location.state.pickedCategory, (cate) => ({ id: cate.id })),
      },
      limit: 30,
    },
    fetchPolicy: 'network-only',
  });
  console.log('data: ', data);
  const [saveFollowingBrandsMutation] = useMutation(SAVE_FOLLOWING_BRANDS);
  const onSave = async () => {
    if (sending) return;
    setSending(true);
    const brands = map(pickedBrands, partialRight(pick, 'id'));
    try {
      const {
        data: { saveFollowingBrands },
      } = await saveFollowingBrandsMutation({ variables: { brands } });
      if (saveFollowingBrands.successful) {
        console.log('Save Following Brands Success!');
        liff.sendMessages([
          {
            type: 'text',
            text: 'โปรโมชั่นของฉัน',
          },
        ]);
        liff.closeWindow();
      } else {
        alert(saveFollowingBrands.errors[0].message);
        setSending(false);
      }
    } catch (err) {
      console.log('error => ', err.message);
      alert(err.message);
      setSending(false);
    }
  };
  return (
    <div className="container verify-page select-cate full-height">
      <div className="card">
        <div className="header">
          <div className="ico">
            <img
              src="https://salehere.co.th/static/images/ico-brand-w.png"
              alt=""
              className="tall"
            />
          </div>
          <h3>
            เลือกแบรนด์ที่คุณชื่นชอบ
            <span>เลือกได้มากกว่า 1 แบรนด์</span>
          </h3>
        </div>
        {map(get(data, 'suggestFollowing', []), (category) => {
          return (
            <>
              <h5>{category.category.nameTh}</h5>
              <div className="pick-brand-slider">
                {map(get(category, 'brands', []), (brand) => {
                  const existBrand = find(pickedBrands, { id: brand.id });

                  return (
                    <div
                      className={`brand ${existBrand ? 'current' : ''}`}
                      onClick={() => {
                        if (existBrand) {
                          setPickedBrands(filter(pickedBrands, (c) => c.id !== brand.id));
                        } else {
                          setPickedBrands([...pickedBrands, brand]);
                        }
                      }}
                    >
                      <img src={get(brand, 'logoImg.imgSmallPath', '')} alt="" />
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
        <div className="btn-wrapper text-right">
          <a
            href="#"
            className={`btn ${isEmpty(pickedBrands) ? 'disabled' : ''} ${
              sending ? 'disabled' : ''
            }`}
            onClick={(e) => {
              e.preventDefault();
              if (!isEmpty(pickedBrands)) {
                onSave();
              }
            }}
          >
            ถัดไป
            {' '}
            <i className="fa fa-angle-right" aria-hidden="true" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PickBrand;
