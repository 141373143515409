import { thunk, action } from 'easy-peasy';
import delay from '../utils/delay';

export default {
  isOpen: false,
  message: '',
  setOpen: action((state, isOpen) => {
    state.isOpen = isOpen;
  }),
  setMessage: action((state, message) => {
    state.message = message;
  }),
  openToast: thunk(async (actions, payload) => {
    actions.setOpen(true);
    actions.setMessage(payload.message);
    await delay(payload.timeout || 1000);
    actions.setOpen(false);
    actions.setMessage('');
  }),
};
