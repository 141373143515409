import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

const CampaignCard = (props) => {
  console.log('CampaignCard props: ', props);
  const renderButton = () => {
    if (!props.isActive && !props.isExpire) {
      return <div className="btn gray">เร็วๆนี้</div>;
    }
    if (props.isActive) {
      return <div className="btn">ร่วมกิจกรรม</div>;
    }
    if (
      get(props, 'myReward.isGetReward', false)
      && !get(props, 'myReward.isCompleteForm', false)
    ) {
      return <div className="btn">รับรางวัล</div>;
    }
    return <div className="btn gray">สิ้นสุดกิจกรรม</div>;
  };
  return (
    <div className="col-12 col-lg-4 no-pd">
      <Link
        to={`/brand/${get(props, 'brand.seoSlugUrl', '#')}/campaigns/${get(
          props,
          'seoSlugUrl',
          '#',
        )}`}
      >
        <a className="campaign-slide-card">
          <div className="img">
            <img
              src={get(props, 'coverImage.imgMediumPath', '')}
              alt={get(props, 'coverImage.imgAlt', '')}
            />
          </div>
          <div className="text">
            <div className="brand">
              <div className="img">
                <img
                  src={get(props, 'brand.logoImg.imgThumbPath', '')}
                  alt={get(props, 'brand.logoImg.imgAlt', '')}
                />
              </div>
              {get(props, 'brand.name', '')}
            </div>
            <p>{`${get(props, 'beginDate.short', '')} - ${get(props, 'endDate.short', '')}`}</p>
          </div>
          {renderButton()}
        </a>
      </Link>
    </div>
  );
};
export default CampaignCard;
