import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useStoreActions, useStoreState } from 'easy-peasy';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const SuccessJoinModal = () => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(335, {
    styles: {
      overlay: { paddingBottom: '50px' },
      content: { overflow: 'visible', borderRadius: '15px' },
    },
  });
  const modalRef = useRef(null);
  useEffect(() => {
    if (modalRef) {
      setTimeout(() => {
        if (modalRef.current.node.children.length > 0) {
          modalRef.current.node.children[0].scrollTop = 0;
        }
      }, 200);
    }
  }, []);

  return (
    <Modal
      ref={modalRef}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal();
      }}
      shouldCloseOnOverlayClick={false}
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => {
          closeModal();
        }}
        bodyStyles={{ borderRadius: '15px' }}
      >
        <div
          className={'modal-dialog modal-md campaign success-join'}
          style={{ margin: '0px', width: '100%' }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <p>กิจกรรม</p>
              <div>
                <h3>ยินดีด้วย!</h3>
                <span>คุณร่วมกิจกรรมเรียบร้อยแล้ว</span>
              </div>
            </div>
            <div className="modal-body">
              <p>
                ขอบคุณที่ร่วมสนุกกิจกรรมบน Sale Here จะมี SMS แจ้งเตือนเมื่อคุณได้รับรางวัล
                ในวันที่ประกาศผล
              </p>
            </div>
            <div className="modal-footer">
              <div className={'btn'} onClick={closeModal}>
                ปิด
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default SuccessJoinModal;
