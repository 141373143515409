import React, { useState } from 'react';
import { get } from 'lodash';
import Modal from 'react-modal';
import { useStoreActions, useStoreState } from 'easy-peasy';
import InputMask from 'react-input-mask';
import TextInput from '../../form/text-input';
import useMutation from '../../../hooks/useMutation';
import useVerifyPhone from '../../../hooks/auth/useVerifyPhone';
import useCoupon from '../../../hooks/useCoupon';
import { VERIFY_OTP } from '../../../graphql/mutations/auth';

import { formatPhoneNo } from '../../../utils';
import ModalContainer from '../container';
import { modalStyles } from '../styles';
import { LoadingPureSpinner } from '../../loading';

const CouponVerifyPhoneNoModal = ({ phoneNo, coupon }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);
  const openModal = useStoreActions((dispatch) => dispatch.modal.openModal);

  const openToast = useStoreActions((actions) => actions.toast.openToast);
  const updateUserData = useStoreActions((actions) => actions.user.updateUserData);
  const setPhoneNo = useStoreActions((actions) => actions.coupon.setPhoneNo);
  const {
    claim, use, forceClaim, forceUse,
  } = useCoupon({
    coupon,
  });
  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(400, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const otpReplaced = otp.replace(/ /g, '');
  const validOtp = otpReplaced.length === 6;

  const [loading, setLoading] = useState(false);
  const { timeLeft, onResend } = useVerifyPhone({
    initialPhone: phoneNo,
  });
  const [verifyOtp] = useMutation(VERIFY_OTP, {
    variables: { tel: phoneNo, otp: otpReplaced },
  });
  const onSubmit = async () => {
    if (!validOtp) {
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await verifyOtp();
      if (data.verifyOTP.successful) {
        console.log('success');
        updateUserData({ path: 'user.isActiveBySMS', value: true });
        openToast({
          message: 'ยืนยันเบอร์โทรศัพท์เรียบร้อยแล้ว',
          timeout: 3000,
        });
        setPhoneNo('');
        const status = get(coupon, 'status', '');
        if (status === 'unClaimed') {
          claim();
          return;
        }
        if (status === 'claimed') {
          use();
          return;
        }
      } else {
        setErrorMessage(data.verifyOTP.errors[0].message);
      }
    } catch (err) {
      setErrorMessage(err.message);
      openToast({ message: err.message, timeout: 3000 });
    }
    setLoading(false);
  };
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div
          className="modal-dialog modal-md coupon verify-phone-no"
          style={{ margin: '0px', width: '100%' }}
        >
          <div className="modal-content">
            {loading && (
              <div className="loading-overlay">
                <LoadingPureSpinner />
              </div>
            )}
            <div className="modal-header">
              <div className="ico">
                <img
                  className="tall"
                  src="https://static.salehere.co.th/static/images/ico-verify-phone.png"
                  alt=""
                  title=""
                />
              </div>
              <div className="title">
                <span>ยืนยันเบอร์มือถือ</span>
                <span>เพื่อรับสิทธิพิเศษ</span>
              </div>
            </div>
            <div className="modal-body">
              <h3>รหัสผ่านได้ถูกส่งไปยัง</h3>
              <b className="phone-no">{formatPhoneNo(phoneNo)}</b>
              <h5>โปรดตรวจสอบมือถือของคุณเพื่อยืนยันรหัส</h5>
              <InputMask
                mask="9 9 9 9 9 9"
                maskChar=""
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              >
                {(inputProps) => {
                  return <TextInput type="tel" {...inputProps} onEnter={onSubmit} autoFocus />;
                }}
              </InputMask>
              <span className={errorMessage ? 'has-error' : ''}>
                {errorMessage || 'กรอกรหัส OTP'}
              </span>
              <div className="btn-wrapper">
                {timeLeft > 0 ? (
                  <div className="btn border curve disabled">{`(${timeLeft}) ส่งรหัสผ่านอีกครั้ง`}</div>
                ) : (
                  <div className="btn border curve" onClick={onResend}>
                    ส่งรหัสผ่านอีกครั้ง
                  </div>
                )}
                <div
                  className="btn no-bg resend-btn"
                  onClick={() => {
                    setPhoneNo('');
                    openModal({ type: 'COUPON_ENTER_PHONE_NO' });
                  }}
                >
                  เปลี่ยนเบอร์โทรศัพท์
                </div>
              </div>
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div className={`modal-footer ${validOtp ? '' : 'disabled'}`} onClick={onSubmit}>
              <div>ยืนยัน</div>
            </div>
          </div>
          <div />
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CouponVerifyPhoneNoModal;
