import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { LoadingPureSpinner } from '../loading';

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ show }) => (show ? 1000 : -1000)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const Logo = styled.img`
  width: 200px;
  ${({ pending }) => pending
    && `
    position: absolute;
    top: 10%;
  `}
`;

const FooterText = styled.div`
  color: white;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
`;

const PendingMessage = styled.div`
  position: absolute;
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  font-size: 1.2rem;
  font-weight: 500;
  top: 40%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 24px;
  span {
    display: block;
    margin-top: 20px;
  }
  > div {
    span {
      margin: 0;
    }
  }
`;

const SplashScreen = ({ show, pending }) => {
  useEffect(() => {}, []);
  const appLoading = useStoreState((state) => state.app.appLoading);
  return (
    <Container show={show || appLoading}>
      <Logo src="/images/sh-logo-white-circle.png" pending={pending} />
      {pending && (
        <PendingMessage>
          <LoadingPureSpinner size={36} borderSize={'3px'} />
          <span>{pending}</span>
        </PendingMessage>
      )}
      <FooterText>Powered by Proxumer v2.2.12</FooterText>
    </Container>
  );
};

export default SplashScreen;
