import { get } from 'lodash';
import axios from 'axios';
import { graphQLEndpoint } from '../config';

const trackingException = ({
  name, type = 'exception', message = '', meta = {},
}) => {
  if (process.browser) {
    const user = get(window.store.getState(), 'user', {});
    const userId = get(user, 'user.id', 0);
    axios({
      url: graphQLEndpoint,
      method: 'post',
      data: {
        query: `
          mutation {
            tracking(userId: ${userId}, type: "${type}", name: "${name}", message: "${message}", pageLocation: "${
  window.location.href
}", meta: ${JSON.stringify(JSON.stringify(meta))}) {
              successful
            }
          }
        `,
      },
    });
  }
};

export default trackingException;
