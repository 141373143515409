/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { map, get } from 'lodash';
import { useHistory, Redirect } from 'react-router-dom';

import useQuery from '../../hooks/useQuery';
import { GET_USER_BRANDS } from '../../graphql/queries/user';
import SplashScreen from '../../components/splashscreen';
import PickCategory from '../pick-category';

const MyPromotions = () => {
  const [showPickCategory, setShowPickCategory] = useState(false);
  const history = useHistory();

  const { data, loading } = useQuery(GET_USER_BRANDS, {
    fetchPolicy: 'network-only',
  });
  console.log('data: ', data);
  useEffect(() => {
    if (!loading) {
      if (
        get(data, 'myProfile.statistic.numberOfFollowedBrands') &&
        get(data, 'myProfile.statistic.numberOfFollowedBrands') === 0
      ) {
        // return history.replace('/pick-category');
        setShowPickCategory(true);
      } else {
        liff.sendMessages([
          {
            type: 'text',
            text: 'โปรโมชั่นของฉัน',
          },
        ]);
        liff.closeWindow();
      }
    }
  }, [loading]);
  if (showPickCategory) {
    return <PickCategory />;
  }
  return <SplashScreen show />;
};

export default MyPromotions;
