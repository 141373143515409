import { get } from 'lodash';
import styled from 'styled-components';
import { mobile } from 'bowser';

const zIndex = {
  modal: 11000,
};
export const modalStyles = (maxWidth = 380, options) => {
  return {
    overlay: {
      background: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflowY: 'visible',
      overflowX: 'hidden',
      zIndex: zIndex.modal - 1,
      padding: '.8rem',
      paddingTop: mobile ? '.8rem' : '4rem',
      ...get(options, 'styles.overlay', {}),
    },
    content: {
      position: 'relative',
      background: '#ffffff',
      backgroundClip: 'padding-box',
      borderRadius: '0px',
      border: '0',
      padding: '0',
      zIndex: zIndex.modal,
      width: '100%',
      maxWidth: `${maxWidth}px`,
      top: 'auto',
      bottom: 'auto',
      left: 'auto',
      right: 'auto',
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: '0 4px 24px rgba(0,0,0,0.40)',
      animationDuration: '300ms',
      ...get(options, 'styles.content', {}),
    },
  };
};

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: white;
  overflow: visible;
  ${({ style }) => style}
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 10px;
`;

export const Header = styled.div`
  /* padding: 20px 24px 0; */
  display: ${(props) => (props.noHeader ? 'none' : 'flex')};
`;

export const ModalContent = styled.div`
  label {
    font-size: 1.1em;
  }
`;

export const Footer = styled.div``;

export const CloseButton = styled.div`
  position: absolute;
  right: 15px;
  top: 20px;
  z-index: ${zIndex.modal + 1};
  svg {
    display: block;
    fill: hsla(0, 0%, 50.2%, 0.8);
    height: 15px;
    width: 15px;
    float: right;
    cursor: pointer;
  }
`;
