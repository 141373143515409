import { thunk, action, computed } from 'easy-peasy';
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import { delay } from '../utils/delay';
import { graphQLEndpoint } from '../config';

export default {
  galleryProps: {},
  galleryIndex: 0,
  totalImages: computed((state) => get(state, 'galleryProps.images.length', 0)),
  isOpen: false,
  openGallery: action((state, { data, index }) => {
    state.isOpen = true;
    state.galleryProps = data;
    state.galleryIndex = index;
  }),
  increaseGalleryIndex: action((state) => {
    state.galleryIndex += 1;
  }),
  addImages: action((state, images) => {
    state.galleryProps = {
      ...state.galleryProps,
      images: [...get(state, 'galleryProps.images', []), ...images],
    };
  }),
  clearGallery: action((state) => {
    state.galleryProps = {};
    state.galleryIndex = 0;
  }),
  setIsOpen: action((state, bool) => {
    state.isOpen = bool;
  }),
  closeGallery: thunk(async (actions) => {
    actions.setIsOpen(false);
    await delay(500);
    actions.clearGallery();
  }),
};
