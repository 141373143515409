import { action, computed } from 'easy-peasy';

export default {
  items: [],
  myItems: [],
  totalItems: computed((state) => state.items.length),
  totalMyItems: computed((state) => state.myItems.length),
  setMessageItems: action((state, message) => {
    state.items = [...state.items, message];
  }),
  setMyMessageItems: action((state, message) => {
    state.myItems = [...state.myItems, message];
  }),
};
