import React, { useState, Fragment } from 'react';
import Modal from 'react-modal';
import { get, chain } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import QrReader from 'react-qr-reader';

import useMutation from '../../../hooks/useMutation';
import trackingException from '../../../hooks/useTrackingException';
import { REDEEM_COUPON } from '../../../graphql/mutations/coupon';
import CloseSvg from '../../svg/close';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const QrReaderModal = ({ coupon }) => {
  const [qr, setQr] = useState('');
  console.log('qr: ', qr);
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);
  const openModal = useStoreActions((dispatch) => dispatch.modal.openModal);
  const { isOpen } = useStoreState((state) => state.modal);
  const isMobile = useStoreState((state) => state.app.isMobile);
  const optionsModal = isMobile
    ? {
      styles: {
        overlay: { padding: '0px', paddingTop: '0px' },
        content: { maxWidth: '100%', minHeight: '100vh', height: '100%' },
      },
    }
    : null;
  const styles = modalStyles(380, optionsModal);
  const [redeemCouponMutation] = useMutation(REDEEM_COUPON);
  const onScan = async (value) => {
    console.log('value: ', value);
    // regex split
    const code = chain(value)
      .split('th/coupons?')
      .get('[1]')
      .value();
    if (code) {
      openModal({
        type: 'COUPON_CONFIRM',
        data: {
          coupon,
          onConfirm: async () => {
            try {
              const { data } = await redeemCouponMutation({
                variables: {
                  couponId: get(coupon, 'id', 0),
                  qrCode: code,
                },
                refetchQueries: ['getBrandCoupons', 'getMyCoupons', 'getCoupon'],
              });
              if (data.redeemCoupon.successful) {
                openModal({
                  type: 'COUPON_USED',
                  data: {
                    coupon: {
                      ...coupon,
                      ...get(data, 'redeemCoupon.entity', {}),
                    },
                  },
                });
              } else {
                openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
                trackingException({
                  name: 'QrReaderModal.onScan',
                  message: data.redeemCoupon.errors[0].message,
                });
                alert(data.redeemCoupon.errors[0].message);
              }
            } catch (err) {
              trackingException({
                name: 'QrReaderModal.onScan',
                message: err.message,
              });
              openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
              alert(err.message);
            }
          },
          onCancel: () => {
            openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
          },
        },
      });
    } else {
      if (code.length > 1) {
        closeModal();
        alert('QRCODE ไม่ถูกต้อง');
      }
      // openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
    }
  };
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } })}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        bodyStyles={{ backgroundColor: 'black', color: 'white' }}
        noHeader
        closeModal={() => openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } })}
      >
        <div className="modal-dialog login">
          <div className="modal-header signin">
            <div className="logo">
              <p style={{ color: 'white' }}>Scan QR Code</p>
            </div>
            <div
              className="close-btn"
              onClick={() => openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } })}
            >
              <CloseSvg />
            </div>
          </div>
          <div
            className="modal-body no-pd"
            style={{
              height: 'calc(100vh - 150px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {process.browser && (
              <>
                <QrReader
                  delay={500}
                  viewFinderStyles={{ border: 'none', boxShadow: 'none' }}
                  videoPreviewStyle={{
                    width: `${window.innerWidth}px`,
                    height: `${window.innerWidth}px`,
                  }}
                  onError={() => openModal({ type: 'COUPON_NOT_SUPPORT', data: { coupon } })}
                  onScan={onScan}
                  style={{
                    width: 'calc(100% - 10%)',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <p style={{ textAlign: 'center', marginTop: '50px' }}>
                  Scan QR Code เพื่อใช้ Coupon
                </p>
              </>
            )}
          </div>
          <div className="modal-footer" />
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default QrReaderModal;
