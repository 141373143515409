import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled(motion.div)`
  ${({ bg }) => bg
    && `
    background-color: ${bg};
    span {
        color: #000000 !important;
    }
`}
  img {
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .back-btn {
    width: 12px;
    position: absolute;
    left: 5%;
  }
`;

const Header = ({
  bg, title, logo, back,
}) => {
  return (
    <Container
      initial={{ y: -30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -30, opacity: 0 }}
      transition={{
        x: { type: 'spring', stiffness: 100 },
        default: { duration: 0.5 },
      }}
      className="nav-header"
      bg={bg}
      logo={logo}
    >
      {/* {back && <img class="back-btn" src="/images/back.svg" />} */}
      {logo && <img src={logo} alt="" />}
      <span className="title">{title}</span>
    </Container>
  );
};

export default Header;
