import {
  times, identity, replace, compose, map, concat,
} from 'ramda';
import { each, isEmpty, map as lodashMap } from 'lodash';

export const formatNumberWithComma = (number) => Number(number).toLocaleString('th');

export const formatToFixedNumber = (number) => number.toFixed(1);

export const toList = times(identity);

export const allEqual = (arr) => arr.every((v) => v === arr[0]);

export const formatPhoneNo = (phone) => String(phone)
  .replace(/\s+/g, '')
  .replace(/^(\w{3})(\w{3})(\w{4}).*/, '$1-$2-$3');

const removeBrackets = compose(replace(/\[/g, ''), replace(/\]/g, ''));
export const getPathname = (router) => {
  let pathname = concat('', router.pathname);
  each(router.query, (item, key) => {
    if (pathname.indexOf(key)) {
      pathname = pathname.replace(key, item);
    }
  });
  return removeBrackets(pathname);
};

export const formatTelNo = (tel) => String(tel).charAt(1) === '2'
  ? String(tel)
    .replace(/\s+/g, '')
    .replace(/^(\w{2})(\w{3})(\w{4}).*/, '$1-$2-$3')
  : String(tel)
    .replace(/\s+/g, '')
    .replace(/^(\w{3})(\w{3})(\w{3}).*/, '$1-$2-$3');

export const getFormatPhones = (tels) => {
  if (isEmpty(tels)) return [];
  return lodashMap(tels, (tel) => {
    const formatedTel = tel.tel.replace(/\s+/g, '');
    if (formatedTel.length > 9) {
      return { ...tel, tel: formatPhoneNo(formatedTel) };
    }
    return { ...tel, tel: formatTelNo(formatedTel) };
  });
};

export const isBlank = (str) => {
  return !str || /^\s*$/.test(str);
};

export const escape = (str = '') => (str ? str.replace(/\\/g, '\\\\').replace(/"/g, '\\"') : '');
