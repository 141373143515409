import { action } from 'easy-peasy';
import { map, find } from 'lodash';

export default {
  phoneNo: '',
  setPhoneNo: action((state, phoneNo) => {
    state.phoneNo = phoneNo;
  }),
  couponsUpdated: [],
  setCouponUpdated: action((state, couponData) => {
    const existsCoupon = find(state.couponsUpdated, { id: couponData.id });
    if (existsCoupon) {
      state.couponsUpdated = map(state.couponsUpdated, (coupon) => {
        if (coupon.id === couponData.id) {
          return {
            ...coupon,
            ...couponData,
          };
        }
        return coupon;
      });
    } else {
      state.couponsUpdated = [...state.couponsUpdated, couponData];
    }
  }),
};
