import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    body {
        /* background-color: ${({ theme }) => theme.color.primary}; */
    }
    html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: 100%;
  height: auto;
}
`;
