import { gql } from 'apollo-boost';

export const SEND_OTP = gql`
  mutation sendUserOTP($tel: String!) {
    sendUserOTP(tel: $tel) {
      successful
      errors {
        message
        path
      }
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyOTPMutation($tel: String!, $otp: String!) {
    verifyOTP(tel: $tel, otp: $otp) {
      token
      successful
      errors {
        path
        message
      }
    }
  }
`;

export const LINE_SIGNIN = gql`
  mutation lineSignInMutation($lineToken: String!) {
    lineLiffSignIn(lineToken: $lineToken) {
      token
      successful
      errors {
        path
        message
      }
    }
  }
`;
