import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { get, isEmpty } from 'lodash';
import axios from 'axios';
import { useStoreActions, useStoreState } from 'easy-peasy';
import loadImage from 'blueimp-load-image';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import CloseSvg from '../../svg/close';
import { WRITE_COMMENT } from '../../../graphql/mutations/comment';
import { BRAND_CAMPAIGN_USER_INFO } from '../../../graphql/mutations/campaign';
import useMutation from '../../../hooks/useMutation';
import trackingException from '../../../hooks/useTrackingException';
import upload from '../../../utils/upload';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const ErrorMessage = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  color: #ed1c24;
  margin-top: 5px;
`;

const CommentCampaignModal = ({ campaignId, status, options }) => {
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);
  const openModalOverlay = useStoreActions((actions) => actions.modalOverlay.openModalOverlay);
  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const closeModalOverlay = useStoreActions((actions) => actions.modalOverlay.closeModalOverlay);
  const newComments = useStoreState((state) => state.comment.newComments);
  const newCommentsByCampainId = get(newComments, `campaign.${campaignId}`, []);
  const setNewComments = useStoreActions((actions) => actions.comment.setNewComments);

  const fileInput = useRef(null);
  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(500, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '15px' },
    },
  });
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [tel, setTel] = useState(window ? get(window.store.getState(), 'user.user.tel', '') : '');
  const [error, setError] = useState('');
  const [progressMessage, setProgressMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [writeCommentMutation] = useMutation(WRITE_COMMENT);
  const [brandCampaignUserInfoMutation] = useMutation(BRAND_CAMPAIGN_USER_INFO);
  const onFileChange = (e) => {
    try {
      e.persist();
      const loadImageOptions = { canvas: true };
      loadImage.parseMetaData(e.target.files[0], (data) => {
        if (data.exif && data.exif.get('Orientation')) {
          loadImageOptions.orientation = data.exif.get('Orientation');
        }
        loadImage(
          e.target.files[0],
          (canvas) => {
            trackingException({
              name: 'commentCampaign.onFileChange',
              message: 'load image',
              type: 'analytic',
              meta: {
                filename: e.target.files[0].name,
                size: e.target.files[0].size,
                filetype: e.target.files[0].type,
              },
            });
            setImage({
              preview: canvas.toDataURL(e.target.files[0].type),
              filename: e.target.files[0].name,
              size: e.target.files[0].size,
              filetype: e.target.files[0].type,
            });
          },
          loadImageOptions,
        );
      });
    } catch (err) {
      trackingException({ name: 'commentCampaign.onFileChange', message: err.message });
    }
  };

  const submit = async (e) => {
    e.persist();
    if (loading) return;
    console.log('status: ', status);
    setError('');
    if (get(options, 'isRequiredImage') && !image) {
      setError('กรุณาเลือกรูปภาพ');
      return;
    }
    if (!description) {
      setError('กรุณาใส่เนื้อหา');
      return;
    }
    if (!tel) {
      setError('กรุณาใส่เบอร์โทรศัพท์');
      return;
    }
    if (tel && tel.replace(/-/g, '').length < 10) {
      setError('กรุณาใส่เบอร์โทรศัพท์ให้ถูกต้อง');
      return;
    }
    const sendData = async () => {
      setLoading(true);
      try {
        const imageData = {};
        if (image) {
          setProgressMessage('กำลังอัพโหลดรูปภาพ 0%');
          const { data: blobData } = await axios({
            method: 'get',
            url: image.preview,
            responseType: 'blob',
          });
          const file = new File([blobData], image.filename, {
            type: image.filetype,
          });
          const {
            data: {
              result: { url, width, height },
            },
          } = await upload(file, false, {
            onUploadProgress: ({ percentCompleted }) => setProgressMessage(`กำลังอัพโหลดรูปภาพ ${percentCompleted}%`),
          });
          imageData['url'] = url;
          imageData['width'] = width;
          imageData['height'] = height;
        }

        setProgressMessage('กำลังส่งข้อมูล');
        const { data } = await writeCommentMutation({
          variables: {
            type: 'brandCampaign',
            parentId: campaignId,
            form: {
              description,
              images: isEmpty(imageData)
                ? undefined
                : [
                  {
                    imgPath: imageData['url'],
                    width: imageData['width'],
                    height: imageData['height'],
                  },
                ],
            },
          },
          refetchQueries: ['getBrandCampaign'],
        });
        await brandCampaignUserInfoMutation({
          variables: {
            campaignId,
            tel: tel.replace(/-/g, ''),
          },
        });
        trackingException({
          name: 'commentCampaign.submit.sendData',
          type: 'analytic',
          meta: data,
        });
        if (data.writeComment.successful) {
          setNewComments({
            ...get(newComments, 'campaign', {}),
            campaign: {
              [campaignId]: [data.writeComment.comment, ...newCommentsByCampainId],
            },
          });
          // openToast({
          //   message: 'เข้าร่วมกิจกรรมเรียบร้อยแล้ว',
          //   timeout: 2000,
          // });
          openModal({
            type: 'SUCCESS_JOIN_CAMPAIGN',
          });
          if (get(options, 'redirectToCampaign')) {
            window.location.href = options.slugUrl;
          }
          // closeModal();
        } else {
          setError(data.writeComment.errors[0].message);
        }
        console.log('data: ', data);
      } catch (err) {
        trackingException({ name: 'commentCampaign.submit.sendData', message: err.message });
        console.log('err: ', err);
        setError(err);
      }
      setLoading(false);
      setProgressMessage('');
    };
    if (status.isInteract) {
      openModalOverlay({
        type: 'DOUBLE_CHECK',
        data: {
          label: 'คุณเคยเข้าร่วมกิจกรรมนี้แล้ว',
          description: 'หากกด "ยืนยัน" ข้อมูลเดิมของคุณจะถูกแทนที่ด้วยข้อมูลใหม่',
          confirmLabel: 'ยืนยัน',
          onConfirm: async () => {
            closeModalOverlay();
            await sendData();
          },
        },
      });
    } else {
      await sendData();
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal();
      }}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => {
          closeModal();
        }}
        bodyStyles={{ borderRadius: '15px' }}
      >
        <div className={'modal-dialog modal-md campaign'} style={{ margin: '0px', width: '100%' }}>
          <div className="modal-content">
            <div className="modal-header">
              <p>ร่วมกิจกรรม</p>
              <div
                className="close-btn"
                onClick={() => {
                  closeModal();
                }}
              >
                <CloseSvg />
              </div>
            </div>
            <div className="modal-body">
              <div className="upload-img">
                <div className="img-box">
                  <div className="img" onClick={() => (loading ? null : fileInput.current.click())}>
                    {image ? (
                      <img src={image.preview} alt="" style={{ width: '100%' }} />
                    ) : (
                      <img
                        className="card-img-top"
                        src="https://static.salehere.co.th/static/images/ico-add-shopping-img.png"
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
              <label>ข้อความ</label>
              <input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={loading}
              />
              <input
                type="file"
                ref={fileInput}
                onChange={onFileChange}
                style={{ display: 'none' }}
                multiple={false}
                accept="image/jpeg, image/jpg, image/png"
              />
              <label>เบอร์โทรศัพท์</label>
              <div className="campaign-tel">
                <InputMask
                  mask="999-999-9999"
                  maskChar=""
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                  disabled={loading}
                >
                  {(inputProps) => {
                    return <input type="tel" {...inputProps} />;
                  }}
                </InputMask>

                <span>*ใช้สำหรับติดต่อเพื่อมอบของรางวัล และจะไม่เปิดเผยข้อมูลของคุณ</span>
              </div>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>
            <div className="modal-footer">
              <div className={`btn${loading ? ' disabled' : ''}`} onClick={submit}>
                {loading ? progressMessage : 'ยืนยันการร่วมกิจกรรม'}
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CommentCampaignModal;
