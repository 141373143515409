import { gql } from 'apollo-boost';

export const GET_CAMPAIGNS = gql`
  query getCampaigns($limit: Int, $offset: Int, $filter: CampaignFilter) {
    brandCampaigns(limit: $limit, offset: $offset, filter: $filter) {
      id
      title
      isActive
      isExpire
      myReward {
        isGetReward
        isCompleteForm
      }
      coverImage {
        imgAlt
        imgMediumPath
      }
      status {
        isInteract
      }
      brand {
        id
        name
        logoImg {
          imgAlt
          imgThumbPath
        }
        seoSlugUrl
      }
      beginDate {
        short
      }
      endDate {
        short
        iso
      }
      seoSlugUrl
    }
  }
`;

export const GET_CAMPAIGN_COMMENTS = gql`
  query getBrandCampaign($parentSlug: String, $slug: String!, $limit: Int, $offset: Int) {
    slug(parentSlug: $parentSlug, slug: $slug) {
      brandCampaign {
        id
        statistic {
          numberOfComments
        }
        comments(limit: $limit, offset: $offset) {
          id
          commenter {
            id
            displayName
            name
            userSlug
            profileImage {
              id
              path(size: sq80)
              imgThumbPath
            }
          }
          images {
            imgMediumPath
            imgPath
          }
          description
        }
      }
    }
  }
`;

export const GET_BRAND_CAMPAIGN = gql`
  query getBrandCampaign($parentSlug: String, $slug: String!) {
    slug(parentSlug: $parentSlug, slug: $slug) {
      brandCampaign {
        id
        title
        isExpire
        isActive
        isRequiredImage
        isRequiredFollowBrand
        myReward {
          isGetReward
          isCompleteForm
        }
        status {
          isInteract
        }
        brand {
          id
          name
          logoImg {
            imgAlt
            imgThumbPath
            imgMediumPath
          }
          coverImages {
            imgMediumPath
          }
          isVerified
          hasActivePromotion
          status {
            follow {
              count
              isInteract
            }
          }
          category {
            nameTh
            nameEn
            slug
          }
          seoSlugUrl
        }
        coverImage {
          imgAlt
          imgPath
        }
        beginDate {
          short
        }
        endDate {
          short
          iso
        }

        sections {
          __typename
          ... on Image {
            id
            imgPath
            imgFigure
          }
          ... on EntitySectionText {
            textType: type
            value
          }
          ... on EntitySectionList {
            listType: type
            values
          }
          ... on ButtonSection {
            image {
              imgPath
              imgAlt
              imgFigure
              width
              height
            }
            link
          }
        }
      }
    }
  }
`;
