/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { map, get } from 'lodash';

import SplashScreen from '../../components/splashscreen';
import useGeolocated from '../../hooks/useGeolocated';

const NearBy = () => {
  const { coordinates, loadedLocation } = useGeolocated();
  useEffect(() => {
    console.log('coordinates: ', coordinates);
    if (loadedLocation) {
      if (coordinates.latitude) {
        // liff.sendMessages([
        //   {
        //     type: 'text',
        //     text: JSON.stringify(coordinates),
        //   },
        // ]);
        liff.sendMessages([
          {
            type: 'location',
            title: 'ตำแหน่งของฉัน',
            address: '',
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          },
        ]);
      }
      liff.closeWindow();
    }
  }, [coordinates, loadedLocation]);
  return <SplashScreen show />;
};

export default NearBy;
