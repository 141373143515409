import { gql } from 'apollo-boost';

export const SET_LINE_GROUP_ID = gql`
  mutation setLineGroupId($brandId: Int!, $groupId: String!) {
    setLineGroupId(brandId: $brandId, groupId: $groupId) {
      successful
      errors {
        message
        path
      }
    }
  }
`;
