import axios from 'axios';
import _ from 'lodash';

import { uploadEndpoint, storageKey } from '../config';

export default async (file, isPng, options = {}) => {
  const formData = new FormData();
  formData.append('file', file);
  if (options.maxWidth) formData.append('maxWidth', options.maxWidth);
  if (isPng) {
    formData.append('type', 'png-image');
  }

  const resp = await axios.post(uploadEndpoint, formData, {
    headers: {
      authorization: localStorage.getItem(storageKey),
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      if (options.onUploadProgress) {
        options.onUploadProgress({ percentCompleted });
      }
    },
  });

  const url = _.get(resp, 'data.result.url');
  // https://proxumer-titan.s3.ap-southeast-1.amazonaws.com
  if (_.startsWith(url, 'https://proxumer-titan.s3.ap-southeast-1.amazonaws.com/')) {
    const re = /^(https:\/\/\S+)\/v1\/(\w*)(-?)images\/((\S+\.jpg)|(\S+\.png))$/;
    const result = re.exec(url);
    console.log('result: ', result);
    if (result) {
      return {
        data: {
          ...resp.data,
          result: {
            ...resp.data.result,
            url: `https://img.salehere.co.th/p${result[3]}${result[2]}/1200x0/${result[4]}`,
          },
        },
      };
    }
  }

  return resp;
};
