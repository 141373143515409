import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Datetime from 'react-datetime';
import { useStoreActions, useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { find, get } from 'lodash';

import TextInput from '../../form/text-input';
import Select from '../../form/select-option';
import { LoadingPureSpinner } from '../../loading';
import CloseSvg from '../../svg/close';
import { formatDOB } from '../../../utils/date-format';
import useGetReward from '../../../hooks/useGetReward';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const ErrorMessage = styled.span`
  width: 100%;
  display: block;
  text-align: center;
  color: #ed1c24;
`;

const GetRewardModal = ({ brandCampaignId }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);
  const openToast = useStoreActions((actions) => actions.toast.openToast);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(500, {
    styles: {
      overlay: { paddingBottom: '50px' },
      content: { overflow: 'visible', borderRadius: '15px' },
    },
  });
  const {
    firstName,
    lastName,
    email,
    tel,
    gender,
    dob,
    address,
    province,
    district,
    subDistrict,
    zipcode,
    note,
    errorMessages,
    loading,
    onSubmit,
  } = useGetReward({ brandCampaignId });
  const modalRef = useRef(null);
  useEffect(() => {
    if (modalRef) {
      setTimeout(() => {
        if (modalRef.current.node.children.length > 0) {
          modalRef.current.node.children[0].scrollTop = 0;
        }
      }, 200);
    }
  }, []);
  const renderInput = (props, openCalendar) => {
    return (
      // <div>
      //   <input onClick={openCalendar} {...props} readOnly style={{ backgroundColor: '#ffffff' }} />
      // </div>
      <TextInput
        type="text"
        {...props}
        // value={firstName.value}
        readOnly
        // onChange={firstName.onChange}
        error={find(errorMessages, { path: 'dob' })}
        disabled={loading}
        onClick={openCalendar}
        style={{ backgroundColor: '#ffffff' }}
      />
    );
  };

  return (
    <Modal
      ref={modalRef}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal();
      }}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => {
          closeModal();
        }}
        bodyStyles={{ borderRadius: '15px' }}
      >
        <div className={'modal-dialog modal-md campaign'} style={{ margin: '0px', width: '100%' }}>
          <div className="modal-content">
            <div className="modal-header">
              <p>แบบฟอร์ม</p>
              <div
                className="close-btn"
                onClick={() => {
                  closeModal();
                }}
              >
                <CloseSvg />
              </div>
            </div>
            <div className="modal-body">
              <h3>ข้อมูลส่วนตัว</h3>
              <label>ชื่อ</label>
              <TextInput
                type="text"
                value={firstName.value}
                onChange={firstName.onChange}
                error={find(errorMessages, (err) => ['firstname', 'name', 'firstName'].includes(err.path))}
                disabled={loading}
              />
              <label>นามสกุล</label>
              <TextInput
                type="text"
                value={lastName.value}
                onChange={lastName.onChange}
                error={find(errorMessages, (err) => ['lastname', 'name', 'lastName'].includes(err.path))}
                disabled={loading}
              />
              <label>เพศ</label>
              <Select
                onChange={gender.onChange}
                value={gender.value}
                disabled={loading}
                error={find(errorMessages, { path: 'gender' })}
              >
                <option value="" disabled>
                  ยังไม่ได้ระบุ
                </option>
                <option value="M">ชาย</option>
                <option value="F">หญิง</option>
              </Select>
              <label>วันเกิด</label>
              <Datetime
                locale="th"
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
                inputProps={{ placeholder: 'ปี-เดือน-วัน' }}
                value={dob.value ? formatDOB('th', dob.value) : 'ยังไม่ได้ระบุ'}
                closeOnSelect
                renderInput={renderInput}
                onChange={(e) => dob.onChange(moment(e).format('YYYY-MM-DD'))}
              />
              <label>เบอร์โทร</label>
              <InputMask
                mask="999-999-9999"
                maskChar=""
                value={tel.value}
                onChange={tel.onChange}
                disabled={loading}
              >
                {(inputProps) => {
                  return (
                    <TextInput
                      type="tel"
                      {...inputProps}
                      error={find(errorMessages, { path: 'tel' })}
                    />
                  );
                }}
              </InputMask>
              <label>อีเมล</label>
              <TextInput
                type="email"
                value={email.value}
                onChange={email.onChange}
                error={find(errorMessages, { path: 'email' })}
                disabled={loading}
              />
              <h3>ข้อมูลการจัดส่ง</h3>
              <label>รายละเอียดที่อยู่</label>
              <TextInput
                type="text"
                value={address.value}
                onChange={address.onChange}
                error={find(errorMessages, { path: 'address' })}
                disabled={loading}
              />
              <label>ตําบล/แขวง</label>
              <TextInput
                type="text"
                value={subDistrict.value}
                onChange={subDistrict.onChange}
                error={find(errorMessages, { path: 'subDistrict' })}
                disabled={loading}
              />
              <label>อําเภอ/เขต</label>
              <TextInput
                type="text"
                value={district.value}
                onChange={district.onChange}
                error={find(errorMessages, { path: 'district' })}
                disabled={loading}
              />
              <label>จังหวัด</label>
              <TextInput
                type="text"
                value={province.value}
                onChange={province.onChange}
                error={find(errorMessages, { path: 'province' })}
                disabled={loading}
              />
              <label>รหัสไปรษณีย์</label>
              <InputMask
                mask="99999"
                maskChar=""
                value={zipcode.value}
                onChange={zipcode.onChange}
                disabled={loading}
              >
                {(inputProps) => {
                  return (
                    <TextInput
                      type="text"
                      inputMode="decimal"
                      {...inputProps}
                      error={find(errorMessages, { path: 'zipcode' })}
                    />
                  );
                }}
              </InputMask>
              <h3>หมายเหตุ</h3>
              <TextInput
                type="text"
                value={note.value}
                onChange={note.onChange}
                // error={find(errorMessages, { path: 'zipcode' })}
                disabled={loading}
              />
              {!loading && find(errorMessages, { path: 'all' }) && (
                <ErrorMessage>{get(errorMessages, '[0].message', '')}</ErrorMessage>
              )}
            </div>
            <div className="modal-footer">
              <div className={`btn${loading ? ' disabled' : ''}`} onClick={onSubmit}>
                {loading ? <LoadingPureSpinner color="white" /> : 'บันทึก'}
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default GetRewardModal;
