import React from 'react';
import {
  ModalBody, Header, ModalContent, Title, CloseButton,
} from './styles';

const ModalContainer = ({
  closeModal, children, title, noHeader, bodyStyles = {},
}) => {
  return (
    <ModalBody style={bodyStyles}>
      <Header noHeader={noHeader} className="modal__header">
        {title && <Title className="modal__title">{title}</Title>}
        <CloseButton className="modal__close__btn" onClick={() => closeModal()}>
          <svg viewBox="0 0 12 12">
            <path
              fillRule="evenodd"
              d="M11.5 10.5c.3.3.3.8 0 1.1-.3.3-.8.3-1.1 0L6 7.1l-4.5 4.5c-.3.3-.8.3-1.1 0-.3-.3-.3-.8 0-1.1L4.9 6 .5 1.5C.2 1.2.2.7.5.4c.3-.3.8-.3 1.1 0L6 4.9 10.5.4c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1L7.1 6l4.4 4.5z"
            />
          </svg>
        </CloseButton>
      </Header>
      <ModalContent>{children}</ModalContent>
    </ModalBody>
  );
};

export default ModalContainer;
