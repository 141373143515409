import { gql } from 'apollo-boost';

export const GET_MESSAGES = gql`
  query getMessages($conversationSlug: String!, $limit: Int, $offset: Int) {
    messages(conversationSlug: $conversationSlug, limit: $limit, offset: $offset) {
      detail {
        name
        type
        slugUrl
        profileImage {
          imgThumbPath
          imgAlt
        }
        isVerified
      }
      items {
        id
        sender {
          name
          profileImage {
            imgThumbPath
            imgAlt
          }
        }
        isMe
        description
        images {
          imgThumbPath
          imgAlt
        }
        source {
          __typename
          ... on Brand {
            id
            name
            logoImg {
              imgThumbPath
              imgAlt
            }
          }
          ... on Promotion {
            id
            title
          }
          ... on OrderPayment {
            _id:id
            status
            products (limit: 3) {
              product {
                images(limit: 1) {
                  imgThumbPath
                  imgAlt
                }
              }
            }
          }
          ... on CatalogProduct {
            _id:id
            name
            images(limit: 1) {
              imgThumbPath
              imgAlt
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CONVERSATIONS = gql`
  query getConversations($limit: Int, $offset: Int, $filter: ConversationFilter) {
    conversations(limit: $limit, offset: $offset, filter: $filter) {
      slugUrl
      detail {
        name
        profileImage {
          imgThumbPath
          imgAlt
        }
        isVerified
      }
      isRead
      lastMessage
      numberOfMessages
      updatedAt
    }
  }
`;
