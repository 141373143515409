import React from 'react';
import Modal from 'react-modal';
import { useStoreActions, useStoreState } from 'easy-peasy';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const CouponWarningModal = ({ title, description }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(500, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick={false}
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div className="modal-dialog modal-md coupon" style={{ margin: '0px', width: '100%' }}>
          <div className="modal-content">
            <div className="modal-body">
              <h3>{title}</h3>
              <p>{description}</p>
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div className="modal-footer" onClick={closeModal}>
              กลับ
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CouponWarningModal;
