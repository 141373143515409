require('dotenv').config();

const env = process.env.REACT_APP_STAGE || 'development';
const commonConfig = {
  port: 3000,
  env,
  baseURL: 'http://localhost:3000',
  baseFrontURL: 'https://dev.salehere.co.th',
  graphQLEndpoint: 'https://dev-api-admin.salehere.co.th/graphql',
  subScriptionEndpoint: 'wss://dev-api-admin.salehere.co.th/subscriptions',
  uploadEndpoint: 'https://dev-api-admin.salehere.co.th/v1/upload',
  storageKey: 'shliffdev',
  omiseSecretKey: process.env.OMISE_SECRET_KEY,
  omisePublicKey: process.env.OMISE_PUBLIC_KEY,
  liffId: '1655793333-pd463yGw',
  publicKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmLU60ivUgYqo49yXzhBN
3/ZHqTykdRJbK8hyjk0fv9GCr5skzkB0fXq42DH5O41EvnHQyynEJTCFnQJIMedj
B0tCcpF9gr5lmCKcaBw1pP76rAUxaCE6nvqtjkqO0yHM6qIzAxDx3tGdnHV5ikiA
sDmHDOQVepGdg5JgUk6GzQYAQD82X49nY3NlYtui1PRnl+bexCxbOO2GZme642Qz
7kXuaXUygZHrmWKKoJERC7txYWhzwpTppgurmsbwehj4i/v/c8Xkagm5cqs7Vz3e
p6pOtv5wi3wCPF0bLXEmWXLkYZCzrVcWdv20yKr3THZ/Jabyb6/9IERrwmutjmS7
dwIDAQAB
-----END PUBLIC KEY-----`,
};

const environmentConfig = {
  development: {},
  uat: {
    baseFrontURL: 'https://dev.salehere.co.th',
    graphQLEndpoint: 'https://dev-api.salehere.co.th/graphql',
    uploadEndpoint: 'https://dev-api.salehere.co.th/v1/upload',
    subScriptionEndpoint: 'wss://dev-api.salehere.co.th/subscriptions',
    storageKey: 'shliffuat',
    liffId: '1655793333-pd463yGw',
  },
  production: {
    baseFrontURL: 'https://salehere.co.th',
    graphQLEndpoint: 'https://api-admin.salehere.co.th/graphql',
    uploadEndpoint: 'https://api-admin.salehere.co.th/v1/upload',
    subScriptionEndpoint: 'wss://api-admin.salehere.co.th/subscriptions',
    storageKey: 'shliff',
    liffId: '1653960190-VGD5n2n2',
  },
};

module.exports = Object.assign(commonConfig, environmentConfig[env]);
