import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  input {
    transition: none;
  }
  ${(props) => props.error
    && `
  margin-bottom: 10px;
  select {
    margin-bottom: 0 !important;
    border: 2px solid #ed1c24;
  }
`}
`;

const ErrorMessage = styled.span`
  color: #ed1c24;
  margin-top: 5px;
  display: block;
`;
const SuccessMessage = styled.span`
  color: #4caf50;
  margin-top: 5px;
  display: block;
`;

const Select = ({
  error, success, children, ...props
}) => {
  return (
    <Container error={!!error} style={props.containerStyle || {}}>
      <select {...props}>{children}</select>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      {success && <SuccessMessage>{success.message}</SuccessMessage>}
    </Container>
  );
};

export default Select;
