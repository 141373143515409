import { useQuery } from '@apollo/react-hooks';
import {
  curry, get, omitBy, isFunction, chain, setWith, clone,
} from 'lodash';
import { propOr } from 'ramda';

const getVariables = propOr({}, 'variables');

const getTotalLoadmoreableItem = curry((pathOfLoadmoreableItem, items) => chain(items)
  .get(`${pathOfLoadmoreableItem}`)
  .size()
  .value());

const getLoadmoreableItem = curry((path, items) => get(items, `${path}`));
const getLimit = (limit, offset) => (isFunction(limit) ? limit(offset) : limit);

const useQueryWithLoadMore = (query, queryOptions, meta) => {
  const {
    data, loading, fetchMore, refetch, networkStatus,
  } = useQuery(query, queryOptions);

  const loadMoreHandler = async ({ pathOfLoadmoreableItem, limit, onDone }) => {
    const offset = getTotalLoadmoreableItem(pathOfLoadmoreableItem || meta.pathOfLoadmoreableItem)(
      data,
    );
    const variables = {
      ...getVariables(queryOptions),
      offset,
      ...omitBy({ limit: getLimit(limit, offset) }, (i) => !i),
    };
    let hasMore = true;
    await fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        const prevData = getLoadmoreableItem(pathOfLoadmoreableItem || meta.pathOfLoadmoreableItem)(
          prev,
        );
        const fetchMoreResultData = getLoadmoreableItem(
          pathOfLoadmoreableItem || meta.pathOfLoadmoreableItem,
        )(fetchMoreResult);
        const mergeData = [...prevData, ...fetchMoreResultData];
        if (fetchMoreResultData.length < limit) {
          hasMore = false;
        }
        return setWith(clone(data), pathOfLoadmoreableItem, mergeData, clone);
      },
    });
    if (onDone) {
      onDone({ hasMore });
    }
    return { offset };
  };
  return {
    data,
    loadMore: loadMoreHandler,
    loading,
    refetch,
    networkStatus,
  };
};

export default useQueryWithLoadMore;
