import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import useSubscription from '../../hooks/useSubscription';
import { NEW_MESSAGE } from '../../graphql/subscriptions/message';

const MessageSubscription = () => {
  const authenticated = useStoreState((state) => state.user.authenticated);
  const setMessageItems = useStoreActions((actions) => actions.message.setMessageItems);
  useSubscription(NEW_MESSAGE, {
    skip: !authenticated || !process.browser,
    onSubscriptionData: ({ subscriptionData }) => {
      setMessageItems(subscriptionData.data.newMessages);
    },
  });
  return null;
};

export default MessageSubscription;
