import React from 'react';
import { useStoreState } from 'easy-peasy';
import DoubleCheckModal from './double-check-modal';

const MODAL_COMPONENTS = {
  DOUBLE_CHECK: DoubleCheckModal,
};

const ModalOverlayRoot = () => {
  const { modalType, modalProps } = useStoreState((state) => state.modalOverlay);
  if (!modalType) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[modalType];
  if (ModalComponent) return <ModalComponent {...modalProps} />;
  return null;
};

export default ModalOverlayRoot;
