import React from 'react';
import Modal from 'react-modal';
import { get, isEmpty, map } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ModalContainer from '../container';
import { modalStyles } from '../styles';

const ChoicesModal = ({ choices }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(500, {
    styles: {
      overlay: { alignItems: 'center' },
      content: { overflow: 'visible', borderRadius: '15px' },
    },
  });

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div className="modal-dialog modal-md choices" style={{ margin: '0px', width: '100%' }}>
          <div className="modal-content" style={{ borderRadius: '15px' }}>
            {!isEmpty(choices)
              && map(choices, (item, index) => {
                if (item.render) {
                  return item.render();
                }
                if (item.hidden) {
                  return null;
                }
                return (
                  <div
                    key={`choice-modal-${index}`}
                    className="modal-body text-center pointer"
                    style={{
                      borderBottom: '1px solid lightgray',
                    }}
                    onClick={item.onClick}
                  >
                    <h5 style={{ color: get(item, 'color', 'black') }}>{get(item, 'label', '')}</h5>
                  </div>
                );
              })}
            <div className="modal-body text-center pointer" onClick={closeModal}>
              <h5>ยกเลิก</h5>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default ChoicesModal;
