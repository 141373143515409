import { gql } from 'apollo-boost';
import { COMMENT_CARD } from '../fragments/comment';

export const REMOVE_COMMENT = gql`
  mutation removeComment($id: Int!) {
    deleteComment(id: $id) {
      successful
      errors {
        message
      }
    }
  }
`;

export const WRITE_COMMENT = gql`
  ${COMMENT_CARD}
  mutation writeComment(
    $type: CommentType!
    $parentId: Int!
    $form: CommentForm!
    $likeUserLimit: Int
    $likeUserOffset: Int
  ) {
    writeComment(type: $type, parentId: $parentId, form: $form) {
      successful
      errors {
        path
        message
      }
      comment {
        ...CommentCard
      }
    }
  }
`;
