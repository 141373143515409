import { useState, useEffect } from 'react';

if (process.browser) require('intersection-observer'); // eslint-disable-line

const defaultOptions = {
  once: false,
  delay: 600,
  threshold: 0,
  modal: false,
};
const useOnScreen = (ref, options = defaultOptions) => {
  const overideOptions = { ...defaultOptions, ...options };
  const [isIntersecting, setIntersecting] = useState(false);
  const rootMargin = '10px';
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setTimeout(() => {
          setIntersecting(entry.isIntersecting);
        }, overideOptions.delay);

        if (overideOptions.once && entry.isIntersecting && observer && ref.current) {
          observer.unobserve(ref.current);
        }
      },
      {
        threshold: overideOptions.threshold,
        rootMargin,
        root: overideOptions.modal ? document.querySelector('.modal-dialog') : null,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return isIntersecting;
};

export default useOnScreen;
