import { gql } from 'apollo-boost';

export const GET_BRAND_INFO = gql`
  query getBrandInfo($brandId: Int!) {
    brand(id: $brandId) {
      id
      logoImg {
        imgPath
      }
    }
  }
`;

export const GET_BRAND_BY_LINE_GROUP_ID = gql`
  query getBrandByGroupId($groupId: String!) {
    brandByGroupId(groupId: $groupId) {
      id
      name
      logoImg {
        imgPath
      }
    }
  }
`;
