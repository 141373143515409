import React from 'react';
import { filter, get, isEmpty } from 'lodash';

const CampaignAnnounced = ({ sections }) => {
  console.log('CampaignAnnounced sections: ', sections);
  const highlightSections = filter(sections, { textType: 'highlight' });
  console.log('CampaignAnnounced highlightSections: ', highlightSections);
  if (isEmpty(highlightSections)) return null;
  return (
    <div className="announced">
      <p>ประกาศรางวัลกิจกรรม</p>
      <div className="user-lists">{get(highlightSections, '[0].value', '')}</div>
    </div>
  );
};

export default CampaignAnnounced;
