/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { get, isEmpty, map, find } from 'lodash';
import { motion } from 'framer-motion';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Header from '../../components/header';
import useQuery from '../../hooks/useQuery';
import useCoupon from '../../hooks/useCoupon';
import { GET_COUPON } from '../../graphql/queries/coupon';
import { formatNumberWithComma } from '../../utils';
import config from '../../config';

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const staggerTitle = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
  },
  exit: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const fadeInLeft = {
  initial: {
    x: 60,
    opacity: 0,
  },
  exit: {
    x: 60,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const fadeInRight = {
  initial: {
    x: -60,
    opacity: 0,
  },
  exit: {
    x: -60,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const STATUS = {
  unClaimed: 'รับคูปอง',
  claimed: 'ใช้คูปอง',
  redeemed: 'ใช้แล้ว',
  expired: 'หมดอายุ',
  full: 'สิทธิ์เต็ม',
};

const CLASS_STATUS = {
  unClaimed: '',
  claimed: 'received',
  redeemed: 'used',
  expired: 'used',
  full: 'used',
};

const CouponActionButton = ({ data, refetch }) => {
  const { claim, use, loading } = useCoupon({
    coupon: get(data, 'slug.coupon', {}),
    // onDone: () => {
    //   refetch();
    // },
  });
  const couponsUpdated = useStoreState((state) => state.coupon.couponsUpdated);
  console.log('couponsUpdated: ', couponsUpdated);
  const updatedCoupon = find(couponsUpdated, { id: get(data, 'slug.coupon.id') });
  console.log('updatedCoupon: ', updatedCoupon);
  const status = updatedCoupon
    ? get(updatedCoupon, 'status', '')
    : get(data, 'slug.coupon.status', '');
  const redeemedTime = updatedCoupon
    ? get(updatedCoupon, 'redeemedTime.shortDateTime', '')
    : get(data, 'slug.coupon.redeemedTime.shortDateTime', '');
  const redeemedAt = updatedCoupon
    ? get(updatedCoupon, 'redeemedAt', '')
    : get(data, 'slug.coupon.redeemedAt', '');
  const getStatusLabel = () => {
    return STATUS[
      updatedCoupon ? get(updatedCoupon, 'status', '') : get(data, 'slug.coupon.status', '')
    ];
  };
  return (
    <div
      className={`get-coupon ${loading ? 'disabled' : ''}`}
      onClick={() => {
        window.location.href = `${config.baseFrontURL}/${get(
          data,
          'slug.coupon.brand.seoSlugUrl',
          '',
        )}/coupons/${get(data, 'slug.coupon.seoSlugUrl')}`;
        // if (status === 'unClaimed') {
        //   claim();
        // } else if (status === 'claimed') {
        //   use();
        // }
      }}
    >
      <div className="get-btn" style={status === 'redeemed' ? { flexDirection: 'column' } : {}}>
        {status === 'redeemed' ? (
          <React.Fragment>
            <b>{`ใช้แล้วเมื่อ ${redeemedTime.replace('เมื่อ', 'เวลา')}`}</b>
            <span style={{ marginTop: '5px', fontSize: '.8rem' }}>{`สาขา ${redeemedAt}`}</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <img src="https://static.salehere.co.th/static/images/ico-coupon.png" alt="" />
            {loading ? 'กำลังรับคูปอง' : getStatusLabel()}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const CouponInfo = (props) => {
  console.log('props: ', props);
  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const { data, loading: couponInfoLoading, refetch, networkStatus } = useQuery(GET_COUPON, {
    variables: {
      slug: get(props, 'match.params.slug'),
      parentSlug: get(props, 'match.params.brandSlug'),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  if (couponInfoLoading) return <div />;
  console.log('data: ', data);

  return (
    <motion.div
      initial="initial"
      animate="animate"
      className="container-fluid info-page"
      exit={{ opacity: 0 }}
    >
      <Header
        bg="#F4F4F4"
        title={get(data, 'slug.coupon.brand.name', '')}
        logo={get(data, 'slug.coupon.brand.logoImg.imgMediumPath', '')}
        back
      />
      <div className="row">
        <div className="col-12 col-lg-8" style={{ margin: '0 auto' }}>
          <motion.div
            variants={stagger}
            className={`coupon-info ${get(CLASS_STATUS, get(data, 'slug.coupon.status', ''), '')}`}
          >
            <div className="img">
              <img src={get(data, 'slug.coupon.image.imgPath', '')} alt="" />
            </div>
            <motion.div variants={staggerTitle} className="title">
              <motion.h1 variants={fadeInUp}>{get(data, 'slug.coupon.title')}</motion.h1>
              <motion.p variants={fadeInUp}>{`หมดอายุ ${get(
                data,
                'slug.coupon.endDate.short',
              )}`}</motion.p>
            </motion.div>
            <motion.div variants={staggerTitle} className="text">
              <motion.h3 variants={fadeInRight}>เงื่อนไขการใช้สิทธิ์</motion.h3>
              <motion.p variants={fadeInUp} className="text-p">
                {get(data, 'slug.coupon.description', '')}
              </motion.p>
              {get(data, 'slug.coupon.statistics.numberOfBranches', 0) > 0 && (
                <React.Fragment>
                  <motion.h3 variants={fadeInRight}>สาขาที่ร่วมรายการ</motion.h3>
                  <motion.a
                    variants={fadeInUp}
                    href="#"
                    className="branch"
                    onClick={(e) => {
                      e.preventDefault();
                      openModal({
                        type: 'COUPON_BRANCHES',
                        data: { coupon: get(data, 'slug.coupon', {}) },
                      });
                    }}
                  >
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    {`แสดงรายชื่อ ${get(data, 'slug.coupon.statistics.numberOfBranches', 0)} สาขา`}
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </motion.a>
                </React.Fragment>
              )}
              {isEmpty(get(data, 'slug.coupon.stores', [])) ? null : (
                <React.Fragment>
                  <h3>สถานที่จัดจำหน่าย</h3>
                  <div className="consignment-list">
                    {map(get(data, 'slug.coupon.stores', []), (store) => {
                      return (
                        <div
                          className="logo"
                          key={`store-${store.seoSlugUrl}`}
                          onClick={() =>
                            openModal({
                              type: 'BRANCHES_LIST',
                              data: { brand: store },
                            })
                          }
                        >
                          <img src={store.logoImg.imgMediumPath} alt={store.name} />
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
              <motion.p variants={fadeInLeft} className="amount">
                มีทั้งหมด{' '}
                <span>
                  {formatNumberWithComma(get(data, 'slug.coupon.totalCoupons', 0))} สิทธิ์
                </span>
              </motion.p>
            </motion.div>
            <motion.div
              variants={fadeInUp}
              className="btn-wrapper text-center"
              onClick={async () => {
                await liff.shareTargetPicker([
                  {
                    type: 'flex',
                    altText: get(data, 'slug.coupon.title', ''),
                    contents: {
                      type: 'bubble',
                      hero: {
                        type: 'image',
                        url: get(data, 'slug.coupon.image.imgPath', ''),
                        size: 'full',
                        aspectRatio: '20:13',
                        aspectMode: 'cover',
                        action: {
                          type: 'uri',
                          label: get(data, 'slug.coupon.title', ''),
                          uri: `${config.baseFrontURL}/${get(
                            data,
                            'slug.coupon.brand.seoSlugUrl',
                            '',
                          )}/coupons/${get(data, 'slug.coupon.seoSlugUrl')}`,
                        },
                      },
                      body: {
                        type: 'box',
                        layout: 'vertical',
                        action: {
                          type: 'uri',
                          label: get(data, 'slug.coupon.title', ''),
                          uri: `${config.baseFrontURL}/${get(
                            data,
                            'slug.coupon.brand.seoSlugUrl',
                            '',
                          )}/coupons/${get(data, 'slug.coupon.seoSlugUrl')}`,
                        },
                        contents: [
                          {
                            type: 'text',
                            text: get(data, 'slug.coupon.title', ''),
                            weight: 'bold',
                            size: 'md',
                            wrap: true,
                            offsetTop: '-10px',
                          },
                          {
                            type: 'text',
                            text: `หมดอายุ ${get(data, 'slug.coupon.endDate.short', '')}`,
                            color: '#828282',
                            align: 'center',
                            gravity: 'center',
                            size: 'sm',
                          },
                        ],
                      },
                      footer: {
                        type: 'box',
                        layout: 'horizontal',
                        spacing: 'sm',
                        contents: [
                          {
                            type: 'box',
                            layout: 'horizontal',
                            contents: [
                              {
                                type: 'box',
                                layout: 'vertical',
                                contents: [
                                  {
                                    type: 'image',
                                    url:
                                      'https://static.salehere.co.th/static/images/ico-coupon.png',
                                  },
                                ],
                                width: '30px',
                              },
                              {
                                type: 'box',
                                layout: 'vertical',
                                contents: [
                                  {
                                    type: 'text',
                                    text: 'รับคูปอง',
                                    color: '#ffffff',
                                    align: 'center',
                                    gravity: 'center',
                                  },
                                ],
                                spacing: 'none',
                                margin: 'none',
                                width: '65px',
                                offsetTop: '5px',
                              },
                            ],
                            offsetStart: '100px',
                          },
                        ],
                        flex: 0,
                        backgroundColor: '#ed1c24',
                      },
                    },
                  },
                ]);
              }}
            >
              <div className="btn share">Share</div>
            </motion.div>
            <motion.div variants={fadeInUp} className="line-container">
              <div className="line"></div>
            </motion.div>
            <CouponActionButton data={data} refetch={refetch} />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default CouponInfo;
