import React from 'react';
import styled from 'styled-components';
import { get, isEmpty } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';

import useMutation from '../../../hooks/useMutation';
import { REMOVE_COMMENT } from '../../../graphql/mutations/comment';

const DeleteBtn = styled.div`
  background-color: #c71414;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 5px;
  position: absolute;
  right: 12px;
  top: 20px;
  cursor: pointer;
`;
const CommentCampaignCard = (props) => {
  const user = useStoreState((state) => state.user.user);
  const openGallery = useStoreActions((actions) => actions.gallery.openGallery);
  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);
  const openToast = useStoreActions((actions) => actions.toast.openToast);
  const setRemovedComment = useStoreActions((actions) => actions.comment.setRemovedComment);
  const [removeCommentMutation] = useMutation(REMOVE_COMMENT);

  const onRemoveComment = ({ commentId }) => {
    openModal({
      type: 'CHOICES',
      data: {
        choices: [
          {
            label: 'ลบ',
            color: '#ed1c24',
            onClick: async () => {
              await removeCommentMutation({
                variables: { id: commentId },
                refetchQueries: ['getBrandCampaign'],
                update: (store, { data: { deleteComment } }) => {
                  try {
                    if (deleteComment.successful) {
                      setRemovedComment({ id: commentId, type: 'brandCampaign' });
                      openToast({
                        message: 'ลบความคิดเห็นแล้ว',
                        timeout: 2500,
                      });
                    } else {
                      openToast({
                        message: deleteComment.errors[0].message,
                        timeout: 2500,
                      });
                    }
                  } catch (err) {
                    openToast({ message: err.message, timeout: 2500 });
                  }
                },
              });
              closeModal();
            },
          },
        ],
      },
    });
  };

  return (
    <div className="col-12 col-sm-6 col-md-4 no-pd">
      <div className="campaign-card">
        {get(user, 'id', 0) === get(props, 'commenter.id', 0) && (
          <DeleteBtn onClick={() => onRemoveComment({ commentId: get(props, 'id', 0) })}>
            <svg viewBox="0 0 12 12" style={{ fill: '#ffffff' }}>
              <path
                fillRule="evenodd"
                d="M11.5 10.5c.3.3.3.8 0 1.1-.3.3-.8.3-1.1 0L6 7.1l-4.5 4.5c-.3.3-.8.3-1.1 0-.3-.3-.3-.8 0-1.1L4.9 6 .5 1.5C.2 1.2.2.7.5.4c.3-.3.8-.3 1.1 0L6 4.9 10.5.4c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1L7.1 6l4.4 4.5z"
              />
            </svg>
          </DeleteBtn>
        )}
        <div className="user">
          <div className="img" onClick={() => {}}>
            <img
              src={
                get(
                  props,
                  'commenter.profileImage.path',
                  'https://static.salehere.co.th/static/images/img-user-blank.png',
                ) || 'https://static.salehere.co.th/static/images/img-user-blank.png'
              }
              alt={get(props, 'commenter.name', '')}
              style={{ borderRadius: '50%' }}
            />
          </div>
          <p>{get(props, 'commenter.displayName', '')}</p>
        </div>
        {!isEmpty(get(props, 'images', [])) && (
          <div className="img-box">
            <div
              className="img"
              onClick={() => openGallery({
                data: { images: get(props, 'images', []), actionButton: false },
                index: 0,
              })}
            >
              <img
                className="card-img-top wide"
                src={get(props, 'images[0].imgMediumPath', '')}
                alt=""
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </div>
          </div>
        )}
        <p>{get(props, 'description', '')}</p>
      </div>
    </div>
  );
};
export default CommentCampaignCard;
