import { gql } from 'apollo-boost';

export const NEW_MESSAGE = gql`
  subscription newMessagesSubscription {
    newMessages {
      conversationSlug
      message {
        id
        description
        images {
          imgPath
          imgMediumPath
        }
        sender {
          name
          slugUrl
          profileImage {
            imgThumbPath
          }
        }
        isMe
        createdAt
        updatedAt
      }
    }
  }
`;
