import React from 'react';
import Modal from 'react-modal';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ModalContainer from '../container';
import { modalStyles } from '../styles';

const DoubleCheckModal = ({
  label, description, confirmLabel, onConfirm,
}) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modalOverlay.closeModalOverlay);

  const { isOpen } = useStoreState((state) => state.modalOverlay);
  const styles = modalStyles(500, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div className="modal-dialog modal-md coupon un" style={{ margin: '0px', width: '100%' }}>
          <div className="modal-content">
            <div className="modal-body">
              <h3>{label || 'ต้องการลบความคิดเห็นนี้หรือไม่ ?'}</h3>
              <p>{description || ''}</p>
              {/* <hr /> */}
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div className="modal-footer confirm">
              <div className="btn-wrapper">
                <div className="btn no-bg" onClick={closeModal}>
                  ยกเลิก
                </div>
                <div className="btn curve" onClick={onConfirm}>
                  {confirmLabel || 'ลบ'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default DoubleCheckModal;
