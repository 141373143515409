/* eslint-disable no-undef */
import React from 'react';
import Modal from 'react-modal';
import { get } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useMutation from '../../../hooks/useMutation';
import trackingException from '../../../hooks/useTrackingException';
import { REDEEM_COUPON } from '../../../graphql/mutations/coupon';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const CouponUsedChoicesModal = ({ coupon }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);
  const openModal = useStoreActions((dispatch) => dispatch.modal.openModal);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(500, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  const [redeemCouponMutation] = useMutation(REDEEM_COUPON);
  const usePinCode = async () => {
    const pinCode = prompt('กรอก PIN');
    console.log('pinCode: ', pinCode);
    if (pinCode) {
      try {
        const { data } = await redeemCouponMutation({
          variables: {
            couponId: get(coupon, 'id', 0),
            qrCode: pinCode,
          },
          refetchQueries: ['getBrandCoupons', 'getMyCoupons', 'getCoupon'],
        });
        if (data.redeemCoupon.successful) {
          openModal({
            type: 'COUPON_USED',
            data: {
              coupon: { ...coupon, ...get(data, 'redeemCoupon.entity', {}) },
            },
          });
        } else {
          trackingException({
            name: 'CouponUsedChoicesModal.usePinCode',
            message: data.redeemCoupon.errors[0].message,
            meta: {
              pinCode,
            },
          });
          alert(data.redeemCoupon.errors[0].message);
        }
      } catch (err) {
        trackingException({
          name: 'CouponUsedChoicesModal.usePinCode',
          message: err.message,
          meta: {
            pinCode,
          },
        });
        alert(err.message);
      }
    }
  };

  const onScan = async (value) => {
    console.log('value: ', value);
    // regex split
    const code = chain(value)
      .split('th/coupons?')
      .get('[1]')
      .value();
    if (code) {
      openModal({
        type: 'COUPON_CONFIRM',
        data: {
          coupon,
          onConfirm: async () => {
            try {
              const { data } = await redeemCouponMutation({
                variables: {
                  couponId: get(coupon, 'id', 0),
                  qrCode: code,
                },
                refetchQueries: ['getBrandCoupons', 'getMyCoupons', 'getCoupon'],
              });
              if (data.redeemCoupon.successful) {
                openModal({
                  type: 'COUPON_USED',
                  data: {
                    coupon: {
                      ...coupon,
                      ...get(data, 'redeemCoupon.entity', {}),
                    },
                  },
                });
              } else {
                openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
                trackingException({
                  name: 'QrReaderModal.onScan',
                  message: data.redeemCoupon.errors[0].message,
                });
                alert(data.redeemCoupon.errors[0].message);
              }
            } catch (err) {
              trackingException({
                name: 'QrReaderModal.onScan',
                message: err.message,
              });
              openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
              alert(err.message);
            }
          },
          onCancel: () => {
            openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
          },
        },
      });
    } else {
      if (code.length > 1) {
        closeModal();
        alert('QRCODE ไม่ถูกต้อง');
      }
      // openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div className="modal-dialog modal-md coupon" style={{ margin: '0px', width: '100%' }}>
          <div className="modal-content">
            <div className="modal-body">
              <h3>ใช้คูปอง</h3>
              <p>{get(coupon, 'title', '')}</p>
              <div
                className="btn"
                // onClick={() => openModal({ type: 'QR_READER', data: { coupon } })}
                onClick={async () => {
                  const data = await liff.scanCode();
                  if (data) {
                    onScan(data.value);
                  }
                }}
              >
                Scan QR Code
              </div>
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div className="modal-footer use" onClick={usePinCode}>
              Use Pin Code
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CouponUsedChoicesModal;
