/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { get, isEmpty, map, find } from 'lodash';
import { motion } from 'framer-motion';
import { useStoreActions, useStoreState } from 'easy-peasy';

import Header from '../../components/header';
import useQuery from '../../hooks/useQuery';
import useCoupon from '../../hooks/useCoupon';
import { GET_BRAND_CAMPAIGN } from '../../graphql/queries/campaign';
import { formatNumberWithComma } from '../../utils';
import config from '../../config';
import CampaignAnnounced from './announced';
import CampaignDescription from './description';
import CampaignComments from './comments';
import CampaignJoin from './join';

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const staggerTitle = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
  },
  exit: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const fadeInLeft = {
  initial: {
    x: 60,
    opacity: 0,
  },
  exit: {
    x: 60,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const fadeInRight = {
  initial: {
    x: -60,
    opacity: 0,
  },
  exit: {
    x: -60,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const CampaignInfo = (props) => {
  console.log('props: ', props);
  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const { data, loading, refetch, networkStatus } = useQuery(GET_BRAND_CAMPAIGN, {
    variables: {
      slug: get(props, 'match.params.slug'),
      parentSlug: get(props, 'match.params.brandSlug'),
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <div>loading</div>;
  console.log('data: ', data);
  const sections = get(data, 'slug.brandCampaign.sections', []);

  return (
    <motion.div
      initial="initial"
      animate="animate"
      className="container-fluid info-page"
      exit={{ opacity: 0 }}
    >
      <Header
        bg="#F4F4F4"
        title={get(data, 'slug.brandCampaign.brand.name', '')}
        logo={get(data, 'slug.brandCampaign.brand.logoImg.imgThumbPath', '')}
        back
      />
      <div className="row b1">
        <div className="col-lg-8 no-pd order-lg-1">
          <div className="title-description">
            <div className="swiper-container swiper-container-horizontal swiper-container-free-mode">
              <div className="swiper-wrapper" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
                <div className="swiper-slide swiper-slide-active">
                  <img
                    src={get(data, 'slug.brandCampaign.coverImage.imgPath', '')}
                    alt={get(data, 'slug.brandCampaign.coverImage.imgAlt', '')}
                  />
                </div>
              </div>
            </div>
            <div className="loading title-img" />
          </div>
        </div>
        <div className="col-lg-8 no-pd order-lg-3">
          <div className="title-description campaign">
            <div className="row">
              <div className="top-description">
                <div className="tag">กิจกรรม</div>
                <h3>{get(data, 'slug.brandCampaign.title', '')}</h3>
                <p>
                  {`${get(data, 'slug.brandCampaign.beginDate.short', '')} - ${get(
                    data,
                    'slug.brandCampaign.endDate.short',
                    '',
                  )}`}
                </p>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 no-pd order-lg-4">
          <div className="title-description pd campaign">
            <CampaignAnnounced sections={sections} />
            <div className="title-card no-top-mg bold">
              <p> รายละเอียด</p>
            </div>
            <CampaignDescription sections={sections} />
            <CampaignJoin data={data} loading={loading} />
          </div>
        </div>
      </div>
      <CampaignComments
        slug={get(props, 'match.params.slug')}
        parentSlug={get(props, 'match.params.brandSlug')}
      />
    </motion.div>
  );
};

export default CampaignInfo;
