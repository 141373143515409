import React, { useState } from 'react';
import { get, map, uniqBy, filter, last, mergeWith, cloneDeep, chain, isEmpty } from 'lodash';
import { useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';

import InfiniteScroll from '../../components/infinite-scroll';

import useQuery from '../../hooks/useQuery';
import { SET_READ_ALL } from '../../graphql/mutations/message';
import { GET_CONVERSATIONS } from '../../graphql/queries/message';

import useMutation from '../../hooks/useMutation';
import { fromNow } from '../../utils/date-format';

const CONVERSATION_SIZE = 10;

const MessagesSidebar = ({ slug, brand, brandInfo }) => {
  const history = useHistory();

  const newMessageItems = useStoreState((state) => state.message.items);

  const [setReadAll] = useMutation(SET_READ_ALL, {
    variables: { type: 'inbox' },
    refetchQueries: ['getConversations'],
  });
  const { data, loading, loadMore } = useQuery(GET_CONVERSATIONS, {
    variables: { filter: { name: '', brandId: +brand } },
    fetchPolicy: 'network-only',
  });
  const [HASMORE, setHasMore] = useState(true);
  const conversations = map(get(data, 'conversations', []), (conversation) => {
    const lastNewConversation = chain(newMessageItems)
      .filter({ conversationSlug: conversation.slugUrl })
      .last()
      .value();
    if (!isEmpty(lastNewConversation)) {
      return {
        ...conversation,
        lastMessage: get(lastNewConversation, 'message.description', ''),
        isRead: false,
      };
    }
    return conversation;
  });
  console.log('conversations: ', conversations);
  return (
    <div className="side-bar">
      <div className="inbox-title">
        <div style={{ position: 'absolute', width: '40px', left: '10px' }}>
          <img src={get(brandInfo, 'logoImg.imgPath', '')} style={{ borderRadius: '50%' }} alt="" />
        </div>
        <h3>Messenger</h3>
        <p className="pointer" onClick={setReadAll}>
          อ่านทั้งหมด
        </p>
      </div>
      <div className="inbox-msg-list">
        <ul>
          <InfiniteScroll
            items={uniqBy(conversations, 'slugUrl')}
            hasMore={HASMORE}
            loadMore={() =>
              loadMore({
                pathOfLoadmoreableItem: 'conversations',
                limit: CONVERSATION_SIZE,
                onDone: ({ hasMore }) => {
                  setHasMore(hasMore);
                },
              })
            }
            renderItem={(conversation) => {
              return (
                <li
                  onClick={() =>
                    history.push(`/chat?conversation=${conversation.slugUrl}&brand=${brand}`)
                  }
                  className={`${slug === conversation.slugUrl ? 'current' : ''} ${
                    conversation.isRead ? '' : 'new'
                  }`}
                >
                  <div className="user-img">
                    <img src={get(conversation, 'detail.profileImage.imgThumbPath', '')} alt="" />
                  </div>
                  <div className="user-msg">
                    <h4>{get(conversation, 'detail.name', '')}</h4>
                    <h5>
                      <i className="fa fa-clock-o" aria-hidden="true" />
                      {` ${fromNow('th')(get(conversation, 'updatedAt', ''))}`}
                    </h5>
                    <p>{get(conversation, 'lastMessage', '')}</p>
                  </div>
                </li>
              );
            }}
          />
        </ul>
      </div>
    </div>
  );
};

export default MessagesSidebar;
