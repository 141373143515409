import { gql } from 'apollo-boost';
import { COUPON_CARD } from '../fragments/coupon';

export const CLAIM_COUPON = gql`
  ${COUPON_CARD}
  mutation claimCoupon($couponId: Int!) {
    claimCoupon(couponId: $couponId) {
      successful
      errors {
        path
        message
      }
      entity {
        coupon {
          ...CouponCard
        }
      }
    }
  }
`;

export const REDEEM_COUPON = gql`
  ${COUPON_CARD}
  mutation redeemCoupon($couponId: Int!, $qrCode: String!) {
    redeemCoupon(couponId: $couponId, qrCode: $qrCode) {
      successful
      errors {
        path
        message
      }
      entity {
        coupon {
          ...CouponCard
          redeemedAt
          redeemedTime {
            shortDateTime
          }
        }
        redeemedAt
        redeemedTime {
          shortDateTime
        }
      }
    }
  }
`;
