import { gql } from 'apollo-boost';

export const SAVE_FOLLOWING_BRANDS = gql`
  mutation saveFollowingBrands($brands: [InputBrand!]) {
    saveFollowingBrands(brands: $brands) {
      successful
      errors {
        path
        message
      }
    }
  }
`;
