import React from 'react';
import { get } from 'lodash';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

const easing = [0.6, -0.05, 0.01, 0.99];

const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const CLASS_STATUS = {
  unClaimed: '',
  claimed: 'received',
  redeemed: 'received redeemed',
  expired: 'received expired',
  full: 'received expired',
};

const STATUS = {
  unClaimed: 'รับคูปอง',
  claimed: 'ใช้คูปอง',
  redeemed: 'ใช้แล้ว',
  expired: 'หมดอายุ',
  full: 'สิทธิ์เต็ม',
};

const CouponCard = ({ loading, big, ...props }) => {
  console.log('props: ', props);
  const history = useHistory();

  return (
    <div className={get(props, 'className', '') || 'col-12 col-sm-6 no-pd'}>
      <a
        className={`coupon-card ${loading ? 'loading' : ''} ${get(
          CLASS_STATUS,
          get(props, 'status', ''),
          '',
        )}`}
        href={`/brand/${get(props, 'brand.seoSlugUrl')}/coupons/${get(props, 'seoSlugUrl')}`}
        onClick={(e) => {
          e.preventDefault();
          if (loading) return null;
          history.push(
            `/brand/${get(props, 'brand.seoSlugUrl')}/coupons/${get(props, 'seoSlugUrl')}`,
          );
        }}
      >
        <div className="img">
          <img src={get(props, 'image.imgMediumPath', '')} alt="" />
        </div>
        <div className="text">
          <h4>{get(props, 'title', '')}</h4>
          <div className="brand">
            <div className="img">
              <img
                src={get(props, 'brand.logoImg.imgMediumPath', '')}
                alt=""
                style={{ width: '20px' }}
              />
            </div>
            <span>{get(props, 'brand.name', '')}</span>
          </div>
          <p>{`หมดอายุ ${get(props, 'endDate.short', '')}`}</p>
        </div>
        <div className="get-coupon">
          <div className="get-btn">
            <img src="https://static.salehere.co.th/static/images/ico-coupon.png" alt="" />
            <p>{get(STATUS, get(props, 'status', ''), '')}</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default CouponCard;
