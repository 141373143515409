import { useStoreState, useStoreActions } from 'easy-peasy';
import { get } from 'lodash';
import useFollowBrand from './useFollowBrand';

const useJoinCampaign = ({
  campaignId, status, brandCampaign, options = {},
}) => {
  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const { isInteract } = useFollowBrand({
    objId: get(brandCampaign, 'brand.id', 0),
    isInteract: get(brandCampaign, 'brand.status.follow.isInteract', false),
  });
  const isRequiredFollowBrand = get(brandCampaign, 'isRequiredFollowBrand');

  const join = () => {
    if (isRequiredFollowBrand && !isInteract) {
      openModal({ type: 'CAMPAIGN_FOLLOW_BRAND', data: { brandCampaign } });
      return;
    }
    openModal({
      type: 'COMMENT_CAMPAIGN',
      data: { campaignId, status, options },
    });
  };

  const getReward = () => {
    if (isRequiredFollowBrand && !isInteract) {
      openModal({ type: 'CAMPAIGN_FOLLOW_BRAND', data: { brandCampaign, isGetReward: true } });
      return;
    }
    openModal({
      type: 'GET_REWARD',
      data: { brandCampaignId: campaignId },
    });
  };
  return { join, getReward };
};

export default useJoinCampaign;
