import { gql } from 'apollo-boost';

export const REGISTER_REWARD = gql`
  mutation registerReward(
    $brandCampaignId: Int!
    $name: String!
    $email: String!
    $tel: String!
    $address: String!
    $province: String!
    $district: String!
    $subDistrict: String!
    $zipcode: String!
    $note: String!
    $gender: Gender!
    $dob: Date!
  ) {
    registerReward(
      brandCampaignId: $brandCampaignId
      name: $name
      email: $email
      tel: $tel
      address: $address
      province: $province
      district: $district
      subDistrict: $subDistrict
      zipcode: $zipcode
      note: $note
      gender: $gender
      dob: $dob
    ) {
      successful
      errors {
        path
        message
      }
    }
  }
`;

export const BRAND_CAMPAIGN_USER_INFO = gql`
  mutation brandCampaignUserInfo($campaignId: Int!, $tel: String!) {
    brandCampaignUserInfo(campaignId: $campaignId, tel: $tel) {
      successful
      errors {
        path
        message
      }
    }
  }
`;
