import { createStore } from 'easy-peasy';
import appStore from './app';
import modalStore from './modal';
import toastStore from './toast';
import userStore from './user';
import followStore from './follow';
import couponStore from './coupon';
import commentStore from './comment';
import galleryStore from './gallery';
import modalOverlayStore from './modal-overlay';
import messageStore from './message';

export default createStore({
  app: appStore,
  modal: modalStore,
  toast: toastStore,
  user: userStore,
  follow: followStore,
  coupon: couponStore,
  comment: commentStore,
  gallery: galleryStore,
  modalOverlay: modalOverlayStore,
  message: messageStore,
});
