import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../spinner';

const LoadingContainerDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '5rem')};
  span:nth-child(1) {
    &:before {
      margin-top: 0;
      margin-left: 0;
    }
    margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '20px')};
  }
  span:nth-child(2) {
    font-size: 1.1rem;
    color: #5d5d5d;
  }
  ${({ fullWidth }) => fullWidth
    && `
    width: 100%;
  `}
`;

const LoadingWithLabel = ({
  label, size, borderSize, style,
}) => {
  return (
    <LoadingContainerDiv style={style}>
      <Spinner size={size} borderSize={borderSize} inline />
      <span>{label}</span>
    </LoadingContainerDiv>
  );
};

LoadingWithLabel.defaultProps = {
  size: 40,
  borderSize: '3px',
  label: 'กำลังโหลด',
  style: {},
};

const LoadingPureSpinner = ({
  size, borderSize, style, color,
}) => {
  return (
    <LoadingContainerDiv noMargin fullWidth style={style}>
      <Spinner size={size} borderSize={borderSize} color={color} inline />
    </LoadingContainerDiv>
  );
};

LoadingPureSpinner.defaultProps = {
  size: 25,
  borderSize: '2px',
  style: {},
};

const LoadingContainer = ({
  size, borderSize, style, color,
}) => {
  return (
    <div className="w-100" style={{ marginTop: '10px' }}>
      <LoadingPureSpinner size={size} borderSize={borderSize} style={style} color={color} />
    </div>
  );
};

LoadingContainer.defaultProps = {
  size: 25,
  borderSize: '2px',
  style: {},
};

export { LoadingWithLabel, LoadingPureSpinner, LoadingContainer };
