import React from 'react';
import Linkify from 'react-linkify';

import { map, get } from 'lodash';

const getOtherSection = ({ section }) => {
  if (get(section, '__typename') === 'Image') {
    return (
      <figure className="image align-center">
        <img
          src={section.imgPath}
          alt={section.imgAlt}
          title={section.imgAlt}
          style={section.width > 0 ? { maxWidth: `${Math.min(600, section.width)}px` } : {}}
        />
        <figcaption>{section.imgFigure}</figcaption>
      </figure>
    );
  }
  if (get(section, '__typename') === 'ButtonSection') {
    return (
      <figure className="image align-center mb-4">
        <a href={section.link} target="_blank" rel="noopener noreferrer">
          <img
            src={section.image.imgPath}
            alt={section.image.imgAlt}
            title={section.image.imgAlt}
            style={
              section.image.width > 0
                ? { maxWidth: `${Math.min(600, section.image.width)}px`, width: 'auto' }
                : {}
            }
          />
        </a>
      </figure>
    );
  }
  return null;
};

const CampaignDescription = ({ sections }) => {
  return (
    <div className="content text-box text-p">
      {map(sections, (section, index) => {
        if (get(section, '__typename') === 'EntitySectionText' && section.textType === 'text') {
          return (
            <>
              <p>
                <Linkify properties={{ target: '_blank' }}>{section.value}</Linkify>
              </p>
              {/* {index !== sections.length - 1 && <div className="break" />} */}
              {index !== sections.length - 1 && <br />}
            </>
          );
        }
        if (get(section, '__typename') === 'EntitySectionText' && section.textType === 'title') {
          return (
            <h2>
              <Linkify properties={{ target: '_blank' }}>{section.value}</Linkify>
            </h2>
          );
        }
        if (get(section, '__typename') === 'EntitySectionText' && section.textType === 'subTitle') {
          return (
            <h3>
              <Linkify properties={{ target: '_blank' }}>{section.value}</Linkify>
            </h3>
          );
        }
        if (get(section, '__typename') === 'EntitySectionList') {
          const items = map(section.values, (value, valueIndex) => (
            <li key={`list-${index}-${valueIndex}`}>
              <Linkify properties={{ target: '_blank' }}>{value}</Linkify>
            </li>
          ));
          if (section.listType === 'order') {
            return <ol>{items}</ol>;
          }
          return <ul>{items}</ul>;
        }
        return getOtherSection({ section });
      })}
    </div>
  );
};

export default CampaignDescription;
