import { gql } from 'apollo-boost';

export const COUPON_CARD = gql`
  fragment CouponCard on Coupon {
    id
    title
    status
    seoSlugUrl
    image {
      imgMediumPath
    }
    brand {
      id
      name
      logoImg {
        imgMediumPath
      }
      seoSlugUrl
    }
    endDate {
      short
    }
  }
`;
