import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import { get, map, chain } from 'lodash';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useHistory } from 'react-router-dom';

import { LoadingPureSpinner } from '../../components/loading';

import CommentCampaignCard from '../../components/cards/campaign-card/comment-campaign-card';
import useQuery from '../../hooks/useQuery';
import { GET_CAMPAIGN_COMMENTS } from '../../graphql/queries/campaign';

const toUnix = (language, dateString) => {
  const date = moment(dateString)
    .utcOffset('+07:00')
    .locale(language);
  return date.valueOf();
};

const COMMENT_SIZE = 6;
const timestamp = toUnix('th', undefined);

const CampaignComments = ({ slug, parentSlug }) => {
  const history = useHistory();
  console.log('history: ', history);
  const {
    data, loading, loadMore, networkStatus,
  } = useQuery(GET_CAMPAIGN_COMMENTS, {
    variables: {
      parentSlug,
      slug,
      limit: COMMENT_SIZE,
      filter: { timestamp: String(timestamp) },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const newComments = useStoreState((state) => state.comment.newComments);
  const newCommentsByCampainId = get(
    newComments,
    `campaign.${get(data, 'slug.brandCampaign.id', 0)}`,
    [],
  );
  const comments = chain([
    ...newCommentsByCampainId,
    ...get(data, 'slug.brandCampaign.comments', []),
  ])
    .uniqBy((v) => v.id)
    .value();
  const [loadingMore, setLoadingMore] = useState(false);
  const [HASMORE, setHasMore] = useState(true);
  const handleLoadMore = () => {
    setLoadingMore(true);
    loadMore({
      pathOfLoadmoreableItem: 'slug.brandCampaign.comments',
      limit: COMMENT_SIZE,
      onDone: ({ hasMore }) => {
        setHasMore(hasMore);
        setLoadingMore(false);
      },
    });
  };
  const infiniteRef = useInfiniteScroll({
    loading: loadingMore,
    hasNextPage: HASMORE,
    onLoadMore: handleLoadMore,
    scrollContainer: 'window',
  });
  useEffect(() => {
    setHasMore(true);
  }, [networkStatus]);
  console.log('CampaignComments data: ', data);
  return (
    <div className="row" ref={infiniteRef}>
      {map(comments, (comment) => {
        return <CommentCampaignCard {...comment} key={`campaign-comment-${comment.id}`} />;
      })}
      {loadingMore && (
        <div className="col-12" style={{ marginBottom: '20px' }}>
          <LoadingPureSpinner />
        </div>
      )}
    </div>
  );
};

export default CampaignComments;
