import React from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import {
  map, isEmpty, get, has, chain,
} from 'lodash';
import { useStoreActions } from 'easy-peasy';

import { shortenTime } from '../../utils/date-format';

const Img = styled.img`
  border-radius: 4px;
  padding: 5px;
  width: ${({ size }) => size || '110px'};
  height: ${({ size }) => size || '110px'};
  padding: 10px;
  border-radius: 5px;
  margin: 2px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
`;

const Label = styled.label`
  font-size: ${({ xs }) => xs ? 'x-small' : 'small'};
  ${({ space }) => space && 'margin-left: 10px'}
`;

const Card = styled.p`
  box-shadow: 0 4px 8px 0 rgba(0, 140, 186, 0.5);
  transition: 0.3s;
  width: 100%;
  border-radius: 4px;
  background-color: white !important;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  & img {
    object-fit: cover;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid $color-white-1;
  }
  & img:not(:first-child) {
    margin-left: -16px;
    transition: .7s all ease-in-out;
    transform: scale(1);
  }
  & .container {
    margin: auto;
    padding: 2px 16px;
    text-align: center;
    .info {
      margin: 2px !important;
      padding: 2px !important;
      font-size: 70%;
      text-align: right;
      text-decoration: none;
      color: gray;
      transition: opacity 0.20s;
      background: white;
    }
    .subTitle {
      padding: 3px !important;
      font-weight: 100;
      font-size: x-small;    
      background: #353535;
      color: white;
      border-radius: 4px;
    }
  }
`;

export const MessageBox = ({
  index, sender, description, isMe, createdAt, read, images, source, isScrollDown, afterUploadImage, setAfterUploadImage,
}) => {
  // let sourceImages = [];
  // if (!isEmpty(source)) {
  //   switch (get(source, '__typename')) {
  //     // case 'Brand':
  //     //   source.title = get(source, 'name', '');
  //     //   if (has(source, 'logoImg.imgThumbPath')) {
  //     //     sourceImages.push({
  //     //       src: get(source, 'logoImg.imgThumbPath'),
  //     //       alt: get(source, 'logoImg.imgAlt'),
  //     //     });
  //     //   }
  //     //   break;
  //     case 'CatalogProduct':
  //       source.title = get(source, 'name', '');
  //       if (has(source, 'images[0].imgThumbPath')) {
  //         sourceImages.push({
  //           src: get(source, 'images[0].imgThumbPath'),
  //           alt: get(source, 'images[0].imgAlt'),
  //         });
  //       }
  //       break;
  //     case 'OrderPayment':

  //       sourceImages = chain(source).get('products').map((v) => {
  //         return ({
  //           src: get(v, 'product.images[0].imgThumbPath'),
  //           alt: get(v, 'product.images[0].imgAlt'),
  //         });
  //       }).value();
  //       source.title = `#${get(source, '_id', '')}`;
  //       source.subTitle = get(source, 'status', '');
  //       break;

  //     default:
  //       break;
  //   }
  // }

  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const renderSourceEntity = () => {
    // if (get(source, '__typename') === 'CatalogProduct') {
    //   return <div>eiei</div>;
    // }
    return null;
  };

  return (
    <li key={`message-li-${index}`} className={`msg-box ${!isMe ? 'left' : 'right'} ${read ? '' : 'unread'}`}>
      {!isMe && (
        <div className="user-img" key={`message-li-${index}-user-img`}>
          <img
            src={get(sender, 'profileImage.imgThumbPath', '/static/images/img-user-blank.png')}
            alt=""
          />
        </div>
      )}
      <div className="msg" key={`message-li-${index}-msg`}>
        {!isMe && <div className="name">{get(sender, 'name', '')}</div>}
        <p>
          {!isEmpty(images) && map(images, ({ imgThumbPath: src, imgAlt: alt }, k) => (
            <Img
              key={`message-li-${index}-image-${k}`}
              src={src}
              alt={alt}
              size={images.length > 1 ? '55px' : '110px'}
              onLoad={() => {
                if (afterUploadImage) {
                  isScrollDown(true);
                  setAfterUploadImage(false);
                }
              }}
              onClick={() => {
                window.open(src)
                // openModal({ type: 'SHOW_IMAGE', data: { src, alt } });
              }}
            />
          ))}
          {!isEmpty(source) && source.__typename !== 'Brand' && (
            renderSourceEntity()
          )}
          <Linkify properties={{ target: '_blank' }}>
            {description}
          </Linkify>
        </p>
      </div>
      {!isMe ? (
        <div className="time" key={`message-li-${index}-time`}>
          {shortenTime('th')(createdAt)}
        </div>
      ) : (
        <div className="time" key={`message-li-${index}-time`}>
          <span>Read</span>
          {shortenTime('th')(createdAt)}
        </div>
      )}
    </li>
  );
};
