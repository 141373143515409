import { action, thunk } from 'easy-peasy';
import { set } from 'lodash';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { getProfile } from '../utils/api';

export default {
  authenticated: false,
  user: null,
  setUser: thunk(async (actions, token) => {
    actions.setUserToken(token);
    try {
      const myProfile = await getProfile(token);
      actions.setUserData(myProfile);
    } catch (err) {
      console.log('err: ', err);
    }
  }),
  setUserToken: action((state, token) => {
    try {
      const userObject = jwtDecode(token);
      state.user = userObject.user;
      state.authenticated = true;
    } catch (err) {
      state.authenticated = false;
    }
  }),
  setUserData: action((state, user) => {
    state.user = { ...state.user, ...user };
  }),
  setProfileImage: action((state, imgPath) => {
    set(state, 'user.profileImage.imgPath', imgPath);
  }),
  updateUserData: action((state, { path, value }) => {
    set(state, path, value);
  }),
  clearUser: action((state) => {
    Cookie.remove('_px');
    state.user = null;
    state.authenticated = false;
  }),
  setAuthenticated: action((state, authenticated) => {
    state.authenticated = authenticated;
  }),
};
