import React from 'react';
import Modal from 'react-modal';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CloseSvg from '../../svg/close';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const CouponNotSupportModal = ({ coupon }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);
  const openModal = useStoreActions((dispatch) => dispatch.modal.openModal);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(500, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div className="modal-dialog modal-md coupon" style={{ margin: '0px', width: '100%' }}>
          <div className="modal-content">
            <div className="modal-header">
              <div className="close-btn" onClick={closeModal}>
                <CloseSvg />
              </div>
              <img src="https://static.salehere.co.th/static/images/bg-non-coupon.jpg" alt="" />
            </div>
            <div className="modal-body">
              <div className="ico">
                <img src="https://static.salehere.co.th/static/images/ico-non-coupon.png" alt="" />
              </div>
              <h3>ขออภัยในความไม่สะดวก</h3>
              <p>
                ไม่สามารถอ่าน QR code ผ่านหน้านี้ได้ โปรดเปิดลิงก์นี้ผ่านเว็ปบราวเซอร์อื่น อาทิ
                Safari, Google Chrome, Opera Mini
              </p>
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div
              className="modal-footer"
              onClick={() => openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } })}
            >
              กลับ
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CouponNotSupportModal;
