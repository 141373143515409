import React, { Fragment, useRef } from 'react';
import useOnScreen from '../../hooks/useOnScreen';
import { LoadingPureSpinner } from '../loading';

export const BlockViewPort = ({ onEnter }) => {
  const ref = useRef(null);
  const onScreen = useOnScreen(ref);

  if (onScreen) {
    onEnter();
  }
  return (
    <div
      ref={ref}
      style={{
        visibility: 'hidden',
        width: '20px',
        height: '20px',
      }}
    />
  );
};

const InfiniteScroll = ({
  items, renderItem, loadMore, renderLoading, hasMore = true,
}) => {
  const renderItemWithLoading = (item, index, showLoading) => {
    return (
      <>
        {renderItem(item, index)}
        {hasMore
          && (showLoading && renderLoading ? (
            renderLoading()
          ) : (
            <div className="w-100" style={{ marginTop: '10px' }}>
              <LoadingPureSpinner />
            </div>
          ))}
      </>
    );
  };
  return items.map((item, index) => {
    if (index === items.length - 1) {
      return (
        <Fragment key="last-item">
          {renderItemWithLoading(item, index, hasMore)}
          {hasMore && <BlockViewPort onEnter={loadMore} />}
        </Fragment>
      );
    }
    return renderItem(item, index);
  });
};

export default InfiniteScroll;
