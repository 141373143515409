import { useState, useEffect } from 'react';
import { get } from 'lodash';

const useGeolocated = (
  { enableHighAccuracy = false, maximumAge, timeout = 5000 } = {},
  callback,
) => {
  const [loadedLocation, setLoadedLocation] = useState(false);
  const [coordinates, setCoordinates] = useState({
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: null,
    error: null,
  });
  console.log('?????coordinates: ', coordinates);

  const doneLoadingLocation = (coords) => {
    if (process.browser) {
      if (get(coords, 'latitude') && get(coords, 'longitude')) {
        if (!localStorage.getItem('pxLocation')) {
          localStorage.setItem(
            'pxLocation',
            JSON.stringify({
              latitude: get(coords, 'latitude'),
              longitude: get(coords, 'longitude'),
            }),
          );
        }
      }
    }
  };

  useEffect(() => {
    let didCancel;
    const updateCoordinates = ({ coords = {}, timestamp }) => {
      console.log('coords: ', coords);
      const {
        accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed,
      } = coords;
      if (!didCancel) {
        setLoadedLocation(true);
        doneLoadingLocation({ latitude, longitude });
        setCoordinates({
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
          timestamp,
          error: null,
        });
        if (callback instanceof Function) {
          callback({
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            latitude,
            longitude,
            speed,
            timestamp,
            error: null,
          });
        }
      }
    };

    const setError = (error) => {
      // console.log('error: ', error);
      setLoadedLocation(true);
      if (!didCancel) {
        updateCoordinates({
          accuracy: null,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: null,
          longitude: null,
          speed: null,
          timestamp: null,
          error,
        });
      }
    };

    // let watchId;
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 900000,
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateCoordinates, setError, options);
      // watchId = navigator.geolocation.watchPosition(updateCoordinates, setError, {
      //   enableHighAccuracy,
      //   maximumAge,
      //   timeout,
      // });
      // console.log('watchId: ', watchId);
    }
    return () => {
      // if (watchId) {
      //   navigator.geolocation.clearWatch(watchId);
      // }
      didCancel = true;
    };
  }, []);

  return { coordinates, loadedLocation };
};

export default useGeolocated;
