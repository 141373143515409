import { action } from 'easy-peasy';
import { set, get } from 'lodash';

export default {
  isOpenComment: false,
  loading: false,
  mentionQuery: {},
  replyMentionQuery: {},
  commentValue: {},
  replyCommentValue: {},
  mentionsList: {},
  replyMentionsList: {},
  images: [],
  replyImages: [],
  replyCommentAtRootId: {},
  replyCommenter: {},
  newComments: {},
  newReplies: [],
  removed: {},
  openComment: action((state) => {
    state.isOpenComment = true;
  }),
  closeComment: action((state) => {
    state.isOpenComment = false;
  }),
  setLoading: action((state, loading) => {
    state.loading = loading;
  }),
  setMentionQuery: action((state, mentionQuery) => {
    state.mentionQuery = mentionQuery;
  }),
  setCommentValue: action((state, commentValue) => {
    state.commentValue = commentValue;
  }),
  setMentionsList: action((state, mentionsList) => {
    state.mentionsList = mentionsList;
  }),
  setImages: action((state, images) => {
    state.images = images;
  }),
  updateImage: action((state, { index, image }) => {
    set(state, `images[${index}]`, image);
  }),
  setReplyMentionQuery: action((state, replyMentionQuery) => {
    state.replyMentionQuery = replyMentionQuery;
  }),
  setReplyCommentValue: action((state, replyCommentValue) => {
    state.replyCommentValue = replyCommentValue;
  }),
  setReplyMentionsList: action((state, replyMentionsList) => {
    state.replyMentionsList = replyMentionsList;
  }),
  setReplyImages: action((state, images) => {
    state.replyImages = images;
  }),
  updateReplyImage: action((state, { index, image }) => {
    set(state, `replyImages[${index}]`, image);
  }),
  clear: action((state) => {
    state.mentionQuery = {};
    state.commentValue = {};
    state.mentionsList = {};
    state.images = [];
  }),
  clearReply: action((state) => {
    state.replyMentionQuery = {};
    state.replyCommentValue = {};
    state.replyMentionsList = {};
    state.replyImages = [];
    state.replyCommentAtRootId = {};
    state.replyCommenter = {};
  }),
  setReplyCommentAtRootId: action((state, replyCommentAtRootId) => {
    state.replyCommentAtRootId = replyCommentAtRootId;
  }),
  setReplyCommenter: action((state, commenter) => {
    state.replyCommenter = commenter;
  }),
  setNewComments: action((state, comments) => {
    state.newComments = comments;
  }),
  setNewReplies: action((state, replies) => {
    state.newReplies = replies;
  }),
  setRemovedComment: action((state, { id, type }) => {
    // state.removed = [...state.removed, id];
    state.removed = {
      [type]: [...get(state, `removed.${type}`, []), id],
    };
  }),
};
