import { useState } from 'react';
import useMutation from '../useMutation';
import useCountdown from '../useCountdown';
import { SEND_OTP } from '../../graphql/mutations/auth';

const WAIT_SECONDS = 60;

const useVerifyPhone = ({ initialPhone }) => {
  const [phone, setPhone] = useState(initialPhone);
  const [verifyCode, setVerifyCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sended, isSended] = useState(false);

  const [sendUserOTP] = useMutation(SEND_OTP, {
    variables: { tel: phone },
  });
  const { timeLeft, setTimeLeft } = useCountdown(WAIT_SECONDS);
  const onResend = async () => {
    isSended(false);
    try {
      const { data } = await sendUserOTP();
      if (data.sendUserOTP.successful) {
        isSended(true);
        setTimeLeft(WAIT_SECONDS);
      } else {
        setErrorMessage(data.sendUserOTP.errors[0].message);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return {
    phone,
    timeLeft,
    setTimeLeft,
    verifyCode,
    setVerifyCode,
    onResend,
    errorMessage,
    sended,
  };
};

export default useVerifyPhone;
