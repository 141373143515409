/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { get } from 'lodash';

import useFollowBrand from './useFollowBrand';
import useMutation from './useMutation';
import trackingException from './useTrackingException';
import { CLAIM_COUPON, REDEEM_COUPON } from '../graphql/mutations/coupon';

const useCoupon = ({ coupon, onDone }) => {
  const [loading, setLoading] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const brand = get(coupon, 'brand', {});
  const authenticated = useStoreState((state) => state.user.authenticated);

  const openToast = useStoreActions((actions) => actions.toast.openToast);
  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);
  const setCouponUpdated = useStoreActions((actions) => actions.coupon.setCouponUpdated);
  const { isInteract, follow } = useFollowBrand({
    objId: get(brand, 'id', 0),
    isInteract: get(brand, 'status.follow.isInteract', false),
  });
  const [claimCouponMutation] = useMutation(CLAIM_COUPON);
  const [redeemCouponMutation] = useMutation(REDEEM_COUPON);

  const forceClaim = async () => {
    if (forceLoading) return;
    setForceLoading(true);
    try {
      const { data } = await claimCouponMutation({
        variables: { couponId: get(coupon, 'id', 0) },
        update: (store, { data: { claimCoupon } }) => {
          console.log('in UPDATE claimCoupon: ', claimCoupon);
          if (claimCoupon.successful) {
            setCouponUpdated(claimCoupon.entity.coupon);
          }
        },
      });
      if (data.claimCoupon.successful) {
        if (onDone) {
          onDone();
        }
        openModal({ type: 'COUPON_CLAIMED', data: { coupon } });
        trackingException({
          name: 'useCoupon.forceClaim',
          message: 'success',
          meta: data.claimCoupon,
        });
        setForceLoading(false);
      } else {
        trackingException({
          name: 'useCoupon.forceClaim',
          message: data.claimCoupon.errors[0].message,
        });
        if (get(data, 'claimCoupon.errors[0].path') === 'tel') {
          openModal({ type: 'COUPON_ENTER_PHONE_NO', data: { coupon } });
        } else {
          openModal({
            type: 'COUPON_WARNING',
            data: {
              title: 'ไม่สามารถรับคูปองได้ในขณะนี้',
              description: data.claimCoupon.errors[0].message,
            },
          });
        }
        setForceLoading(false);
      }
      console.log('data: ', data);
    } catch (err) {
      console.log('err: ', err);
      trackingException({ name: 'useCoupon.claim', message: err.message });
      openToast({ message: err.message, timeout: 3000 });
      setForceLoading(false);
    }
  };

  const claim = async (options) => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!authenticated) {
      openModal({ type: 'LOGIN' });
      setLoading(false);
      return;
    }
    if (get(options, 'forceFollow', false)) {
      follow(get(brand, 'id', 0));
    } else if (!isInteract) {
      openModal({ type: 'COUPON_FOLLOW_BRAND', data: { coupon } });
      setLoading(false);
      return;
    }
    const isActiveBySMS = get(window.store.getState(), 'user.user.isActiveBySMS');
    if (!isActiveBySMS) {
      const phoneNo = get(window.store.getState(), 'coupon.phoneNo');
      if (phoneNo) {
        const phoneNoReplaced = phoneNo.replace(/-/g, '');
        if (phoneNoReplaced.length === 10) {
          openModal({
            type: 'COUPON_VERIFY_PHONE_NO',
            data: { phoneNo: phoneNoReplaced, coupon },
          });
        } else {
          openModal({ type: 'COUPON_ENTER_PHONE_NO', data: { coupon } });
        }
      } else {
        openModal({ type: 'COUPON_ENTER_PHONE_NO', data: { coupon } });
      }
      setLoading(false);
      return;
    }
    await forceClaim();

    setLoading(false);
  };

  const forceUse = async () => {
    openModal({
      type: 'COUPON_CONFIRM',
      data: {
        coupon,
        onConfirm: async () => {
          const { data } = await redeemCouponMutation({
            variables: {
              couponId: get(coupon, 'id', 0),
              qrCode: '',
            },
            update: (store, { data: { redeemCoupon } }) => {
              console.log('in UPDATE redeemCoupon: ', redeemCoupon);
              if (redeemCoupon.successful) {
                setCouponUpdated(redeemCoupon.entity.coupon);
              }
            },
          });
          if (data.redeemCoupon.successful) {
            if (onDone) {
              onDone();
            }
            openModal({
              type: 'COUPON_USED',
              data: {
                coupon: { ...coupon, ...get(data, 'redeemCoupon.entity', {}) },
              },
            });
            trackingException({
              name: 'useCoupon.forceUse',
              message: 'success',
              meta: data.redeemCoupon,
            });
          } else {
            trackingException({
              name: 'useCoupon.forceUse',
              message: data.redeemCoupon.errors[0].message,
              meta: {
                hashing: '',
              },
            });
            // alert(data.redeemCoupon.errors[0].message);
            openModal({
              type: 'COUPON_WARNING',
              data: {
                title: 'ไม่สามารถใช้คูปองได้ในขณะนี้',
                description: data.redeemCoupon.errors[0].message,
              },
            });
            // closeModal();
          }
        },
        onCancel: () => {
          closeModal();
        },
      },
    });
  };

  const use = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (!authenticated) {
      openModal({ type: 'LOGIN' });
      setLoading(false);
      return;
    }
    if (!isInteract) {
      openModal({ type: 'COUPON_FOLLOW_BRAND', data: { coupon, isUse: true } });
      setLoading(false);
      return;
    }
    const isActiveBySMS = get(window.store.getState(), 'user.user.isActiveBySMS');
    if (!isActiveBySMS) {
      const phoneNo = get(window.store.getState(), 'coupon.phoneNo');
      if (phoneNo) {
        const phoneNoReplaced = phoneNo.replace(/-/g, '');
        openModal({
          type: 'COUPON_VERIFY_PHONE_NO',
          data: { phoneNo: phoneNoReplaced, coupon },
        });
        setLoading(false);
      } else {
        openModal({ type: 'COUPON_ENTER_PHONE_NO', data: { coupon } });
        setLoading(false);
      }
      return;
    }
    if ('') {
      await forceUse();
      setLoading(false);
    } else {
      openModal({ type: 'COUPON_USED_CHOICES', data: { coupon } });
      setLoading(false);
    }
  };
  return {
    claim,
    use,
    forceUse,
    forceClaim,
    loading,
  };
};

export default useCoupon;
