/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { map, get } from 'lodash';
import { useHistory, Redirect } from 'react-router-dom';
import qs from 'query-string';
import { useApolloClient } from '@apollo/react-hooks';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useMutation from '../../hooks/useMutation';
import useQuery from '../../hooks/useQuery';
import useSubscription from '../../hooks/useSubscription';
import { GET_USER_BRANDS } from '../../graphql/queries/user';
import { APPROVE_CHAT_MANAGER_ACTION } from '../../graphql/subscriptions/action';
import { GET_BRAND_INFO } from '../../graphql/queries/brand';
import { IS_CHAT_MANAGER_OF_BRAND } from '../../graphql/mutations/message';
import SplashScreen from '../../components/splashscreen';
import MessagesSidebar from './sidebar';
import MessagesChatRoom from './chat-room';

const ChatPage = (props) => {
  console.log('props: ', props);
  const [showSplash, setShowSplash] = useState(true);
  const [conversationSlug, setConversationSlug] = useState('');
  const [brand, setBrand] = useState('');
  const [pendingMessage, setPendingMessage] = useState('');
  const [brandInfo, setBrandInfo] = useState(null);
  console.log('brandInfo: ', brandInfo);
  const setAppLoading = useStoreActions((actions) => actions.app.setAppLoading);
  const authenticated = useStoreState((state) => state.user.authenticated);
  console.log('authenticated: ', authenticated);
  const history = useHistory();
  const searchQuery = qs.parse(history.location.search);
  const client = useApolloClient();
  console.log('history: ', qs.parse(history.location.search));
  const [isChatManagerOfBrandMutation] = useMutation(IS_CHAT_MANAGER_OF_BRAND);
  const initial = async () => {
    console.log('🚀 ~ file: index.js ~ line 39 ~ initial ~ authenticated', authenticated);
    setShowSplash(true);
    if (get(searchQuery, 'brand')) {
      if (!authenticated) {
        setPendingMessage('ไม่สามารถเชื่อมต่อกับเซิฟเวอร์ได้ กรุณาลองใหม่อีกครั้ง');
        return;
      }
      try {
        const { data: brandInfoData } = await client.query({
          query: GET_BRAND_INFO,
          variables: { brandId: Number(get(searchQuery, 'brand')) },
        });
        if (brandInfoData) {
          setBrandInfo(brandInfoData.brand);
        }
        const { data } = await isChatManagerOfBrandMutation({
          variables: {
            brandId: Number(get(searchQuery, 'brand', 0)),
          },
        });
        if (data.isChatManagerOfBrand.successful) {
          setShowSplash(false);
        } else {
          setPendingMessage('รอการยืนยันจากผู้ดูแลระบบ');
        }
      } catch (err) {
        setPendingMessage(`${err.message}`);
      }
    } else {
      setPendingMessage('ไม่สามารถเข้าถึงข้อมูล');
    }
  };
  useEffect(() => {
    if (authenticated) {
      initial();
    }
    setConversationSlug(get(searchQuery, 'conversation'));
    setBrand(get(searchQuery, 'brand'));
    document.body.style.marginBottom = 0;
    return () => {
      document.body.style.marginBottom = '30px';
    };
  }, [authenticated]);
  useEffect(() => {
    setConversationSlug(get(searchQuery, 'conversation'));
    setBrand(get(searchQuery, 'brand'));
  }, [get(searchQuery, 'conversation'), get(searchQuery, 'brand')]);
  useSubscription(APPROVE_CHAT_MANAGER_ACTION, {
    skip: !get(searchQuery, 'brand'),
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData) {
        initial();
      }
    },
  });
  return (
    <>
      <SplashScreen show={showSplash} pending={pendingMessage} />
      {!showSplash && (
        <div className="body-wrapper inbox">
          <div className={`inbox-page ${conversationSlug ? 'see-msg' : ''}`}>
            <MessagesSidebar slug={conversationSlug} brand={brand} brandInfo={brandInfo} />
            <MessagesChatRoom slug={conversationSlug} brand={brand} brandInfo={brandInfo} />
          </div>
        </div>
      )}
    </>
  );
};

export default ChatPage;
