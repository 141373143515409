import { gql } from 'apollo-boost';

export const GET_USER_BRANDS = gql`
  query getUserBrands {
    myProfile {
      statistic {
        numberOfFollowedBrands
      }
    }
  }
`;

export const SUGGEST_CATEGORY = gql`
  query getSuggestCategories {
    suggestCategories {
      id
      nameTh
      buttonImg {
        imgPath
        imgAlt
      }
    }
  }
`;

export const SUGGEST_FOLLOWING = gql`
  query getSuggestFollowing($filter: FollowingBrandFilter!, $limit: Int) {
    suggestFollowing(filter: $filter, limit: $limit) {
      category {
        id
        nameTh
      }
      brands {
        id
        logoImg {
          imgSmallPath
          imgAlt
        }
      }
    }
  }
`;
