import React from 'react';
import Modal from 'react-modal';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import CloseSvg from '../../svg/close';
import InfiniteScroll from '../../infinite-scroll';

import useGeolocated from '../../../hooks/useGeolocated';
import useQuery from '../../../hooks/useQuery';
import { GET_BRANCHES_COUPON } from '../../../graphql/queries/coupon';

import ModalContainer from '../container';
import { modalStyles } from '../styles';
import config from '../../../config';

const CouponBranchesModal = ({ coupon }) => {
  console.log('coupon: ', coupon);
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);
  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(500, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  const { coordinates, loadedLocation } = useGeolocated();

  const { data, loadMore } = useQuery(GET_BRANCHES_COUPON, {
    variables: {
      slug: coupon.seoSlugUrl,
      parentSlug: coupon.brand.seoSlugUrl,
      filter: get(coordinates, 'latitude')
        ? {
          name: '',
          nearBy: {
            lat: get(coordinates, 'latitude', 0),
            lng: get(coordinates, 'longitude', 0),
          },
        }
        : { name: '' },
    },
    // skip: !loadedLocation,
  });
  const [HASMORE, setHasMore] = React.useState(true);
  let lastGroup = '';

  console.log('data: ', data);
  const brand = get(data, 'slug.coupon.brand', {});
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div
          className="modal-dialog modal-md coupon"
          style={{
            margin: '0px',
            width: '100%',
            maxWidth: '500px',
          }}
        >
          <div className="modal-content">
            <div className="modal-header branch">
              <h3>สาขาที่ร่วมรายการ</h3>
              <div className="bg branch">
                <img src={get(brand, 'coverImages[0].imgMediumPath', '')} alt="" />
              </div>
              <div className="close-btn" onClick={closeModal}>
                <CloseSvg />
              </div>
            </div>
            <div className="modal-body">
              <ul>
                <InfiniteScroll
                  items={get(data, 'slug.coupon.branches', [])}
                  hasMore={HASMORE}
                  loadMore={() => loadMore({
                    pathOfLoadmoreableItem: 'slug.coupon.branches',
                    limit: 10,
                    onDone: ({ hasMore }) => {
                      setHasMore(hasMore);
                    },
                  })}
                  renderItem={(branch) => {
                    console.log('branch: ', branch);
                    const groupName = branch.isNearBy ? 'สาขาใกล้เคียง' : branch.zone[0].name;
                    let showGroup = false;
                    if (lastGroup !== groupName) {
                      lastGroup = groupName;
                      showGroup = true;
                    }
                    return (
                      <React.Fragment key={`branch-${branch.id}`}>
                        {showGroup && (
                          <li className="zone">
                            <span>
                              <i className="fa fa-map-marker" aria-hidden="true" />
                              {groupName}
                            </span>
                          </li>
                        )}
                        <li
                          onClick={() => {
                            window.location.href = `${config.baseFrontURL}/${get(
                              data,
                              'slug.coupon.brand.seoSlugUrl',
                              '',
                            )}/branches/${branch.seoSlugUrl}`;
                          }}
                        >
                          {branch.title}
                        </li>
                      </React.Fragment>
                    );
                  }}
                />
              </ul>
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div data-dismiss="modal" className="modal-footer" onClick={closeModal}>
              ปิด
            </div>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CouponBranchesModal;
