import React from 'react';
import useFollowBrand from 'hooks/useFollowBrand';
import { formatNumberWithComma } from '../../utils';

/**
 * FollowButtonWithFollower - Follow button with follower number
 * @param {Object} analyticData contain dataType, dataSlug, dataState
 */
export const FollowButtonWithFollower = ({ objId, followStatus }) => {
  const { isInteract, toggleFollow, count } = useFollowBrand({
    objId,
    count: followStatus.count,
    isInteract: followStatus.isInteract,
  });
  return (
    <div className="btn-wrapper" style={{ margin: '5px 0px' }}>
      <div
        className={`btn border curve on-card ${isInteract ? 'current' : ''}`}
        onClick={toggleFollow}
      >
        {isInteract ? 'ติดตามแล้ว' : 'ติดตาม'}
      </div>
      <p>
        {formatNumberWithComma(count)}
        {' ติดตาม'}
      </p>
    </div>
  );
};
FollowButtonWithFollower.whyDidYouRender = true;

const FollowButton = ({ followStatus, objId, className }) => {
  const { isInteract, toggleFollow } = useFollowBrand({
    objId,
    isInteract: followStatus.isInteract,
  });
  return (
    <div className={`${isInteract ? 'current' : ''} ${className || ''}`} onClick={toggleFollow}>
      {isInteract ? 'ติดตามแล้ว' : 'ติดตาม'}
    </div>
  );
};

export default FollowButton;
