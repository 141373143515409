import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  get, chain, isEmpty, map, set, filter,
} from 'lodash';
import { useStoreState, useStoreActions } from 'easy-peasy';
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { BlockViewPort } from '../../components/infinite-scroll';

import useQuery from '../../hooks/useQuery';
import { GET_MESSAGES } from '../../graphql/queries/message';
import { shortenDate, toUnix } from '../../utils/date-format';
import { delay } from '../../utils/delay';
import { graphQLEndpoint } from '../../config';
import { MessageBox } from './message';

import MessageTyping from './typing';

const MESSAGE_SIZE = 35;

const LoadingContainer = styled.div`
  position: absolute;
  top: 1%;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  z-index: 10;
  background-color: #cb1616;
  padding: 5px;
  text-align: center;
  border-radius: 15px;
  transition: 0.2s all ease-in-out;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
`;

const LoadingLabel = styled.span`
  color: white;
  margin-left: 10px;
  margin-right: 10px;
`;

const getSystemTime = async () => {
  try {
    const {
      data: {
        data: { system },
      },
    } = await axios({
      url: graphQLEndpoint,
      method: 'post',
      data: {
        query: `
            {
              system {
                currentMilliseconds
              }
            }
          `,
      },
    });
    return system;
  } catch (err) {
    return null;
  }
};

const getMessages = (messages, newMessages, myMessages) => {
  return chain(messages)
    .cloneDeep()
    .unshift(...map(newMessages, 'message'))
    .unshift(...map(myMessages, 'message'))
    .flatten()
    .filter((i) => !isEmpty(i))
    .uniqBy('id')
    .sortBy((i) => toUnix('th')(i.updatedAt))
    .value();
};


const MessageSender = React.memo(({ messagesObject }) => {
  const senderSlug = get(messagesObject, 'detail.slugUrl', '#');
  return (
    <a className="card-user">
      <div className="user-img">
        <img
          src={get(
            messagesObject,
            'detail.profileImage.imgThumbPath',
            '/static/images/img-user-blank.png',
          )}
          alt={get(messagesObject, 'detail.profileImage.imgAlt', '')}
        />
      </div>
      {get(messagesObject, 'detail.isVerified', false) && (
        <div className="check-ico" style={{ fontSize: '1.2em', margin: '10px 5px 10px 0px' }}>
          <i className="fa fa-check-circle" aria-hidden="true" />
        </div>
      )}
      <p>
        <b>{get(messagesObject, 'detail.name', '')}</b>
      </p>
    </a>
  );
});

const scrollToBottom = (element) => {
  if (element.current && process.browser) {
    element.current.scrollIntoView({ behavior: 'auto' });
  }
};

const MessagesChatRoom = ({ slug, brand, brandInfo }) => {
  const history = useHistory();
  const setAppLoading = useStoreActions((actions) => actions.app.setAppLoading);
  const [systemTime, setSystemTime] = useState(null);
  const [scrolldownable, isScrollDown] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [afterUploadImage, setAfterUploadImage] = useState(false);

  const { data: messagesData, loading: messageLoading, loadMore } = useQuery(GET_MESSAGES, {
    variables: { conversationSlug: slug, limit: MESSAGE_SIZE },
    skip: !slug,
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (messageLoading) {
      setAppLoading(true);
    } else {
      setAppLoading(false);
    }
  }, [messageLoading]);
  const messagesObject = get(messagesData, 'messages', []);
  const messageStore = useStoreState((state) => state.message);
  const newMessageItems = filter(messageStore.items, { conversationSlug: slug });
  const myMessageItems = filter(messageStore.myItems, { conversationSlug: slug });
  const messages = getMessages(messagesObject.items, newMessageItems, myMessageItems);
  const messagesEnd = useRef(null);
  const scroller = useRef(null);
  let dateText = '';

  useEffect(() => {
    getSystemTime().then((system) => {
      setSystemTime(+system.currentMilliseconds - moment().valueOf());
    });
  }, []);

  useEffect(() => {
    if (scrolldownable) {
      scrollToBottom(messagesEnd);
    }
  });
  useEffect(() => {
    console.log('message change');
    if (!scrolldownable) {
      isScrollDown(true);
    }
  }, [messageStore]);

  const handleLoadMore = async () => {
    const heightBeforeRender = get(scroller, 'current.scrollHeight', 0);
    if (!loading) {
      setLoading(true);
      if (hasMoreItems) {
        isScrollDown(false);
        await delay(800);
        loadMore({
          pathOfLoadmoreableItem: 'messages.items',
          limit: MESSAGE_SIZE,
          onDone: ({ hasMore }) => {
            setHasMoreItems(hasMore);
            const newHeight = get(scroller, 'current.scrollHeight', 0) - heightBeforeRender;
            set(scroller, 'current.scrollTop', newHeight);
            setLoading(false);
          },
        });
      } else {
        setLoading(false);
      }
    }
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0) {
      handleLoadMore();
    }
  };

  if (messageLoading || !get(messagesObject, 'detail.type')) return <div />;
  return (
    <div className="main">
      <div className="inbox-title">
        <a
          href="#"
          className="btn no-bg back-button"
          onClick={(e) => {
            e.preventDefault();
            // Router.back();
            history.push(`/chat?brand=${brand}`);
          }}
        >
          <i className="fa fa-angle-left" aria-hidden="true" />
        </a>
        <MessageSender messagesObject={messagesObject} />
      </div>
      <div className="inbox-msg-show" style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.3,
          }}
        >
          <img src={get(brandInfo, 'logoImg.imgPath', '')} style={{ borderRadius: '50%' }} alt="" />
        </div>
        <ul className="msg-page" ref={scroller} onScroll={handleScroll}>
          <LoadingContainer hide={!loading}>
            <LoadingLabel>กำลังโหลด</LoadingLabel>
          </LoadingContainer>
          {!isEmpty(messages)
            && map(messages, (message, index) => {
              const currentDateText = shortenDate('')(message.createdAt);
              const showDate = currentDateText !== dateText;
              dateText = currentDateText;
              return (
                <React.Fragment key={`message-box-${index}`}>
                  {/* {index === 0 && !loading && (<BlockViewPort onEnter={() => handleLoadMore()} />)} */}
                  {showDate && (
                    <li className="date" key={`message-date-${index}`}>
                      <p>{currentDateText}</p>
                    </li>
                  )}
                  <MessageBox
                    key={`message-${index}`}
                    index={index}
                    {...message}
                    isScrollDown={isScrollDown}
                    afterUploadImage={afterUploadImage}
                    setAfterUploadImage={setAfterUploadImage}
                  />
                </React.Fragment>
              );
            })}
          <div style={{ float: 'left', clear: 'both' }} ref={messagesEnd} />
        </ul>
        <MessageTyping
          slug={slug}
          receiver={get(messagesObject, 'detail')}
          serverOffset={systemTime}
          brand={brand}
          isScrollDown={isScrollDown}
          setAfterUploadImage={setAfterUploadImage}
        />
      </div>
    </div>
  );
};
MessagesChatRoom.whyDidYouRender = true;

export default MessagesChatRoom;
