import { gql } from 'apollo-boost';
import { COUPON_CARD } from '../fragments/coupon';

export const GET_COUPONS = gql`
  ${COUPON_CARD}
  query getCoupons($limit: Int, $offset: Int, $filter: CouponCategoryFilter, $hashing: String) {
    coupons(
      limit: $limit
      offset: $offset
      filter: { type: available, filter: $filter, hashing: $hashing }
    ) {
      ...CouponCard
      image {
        imgPath
        imgAlt
      }
      totalCoupons
      description
      redeemedAt
      redeemedTime {
        shortDateTime
      }
      statistics {
        numberOfBranches
      }
      updatedDate {
        iso
      }
      createdDate {
        iso
      }
      brand {
        id
        name
        seoSlugUrl
        isStore
        hasActivePromotion
        category {
          nameTh
          nameEn
        }
        coverImages {
          imgMediumPath
        }
        logoImg {
          imgMediumPath
        }
        status {
          follow {
            count
            isInteract
          }
        }
      }
    }
  }
`;

export const GET_BRANCHES_COUPON = gql`
  query getBranchesCoupon(
    $slug: String!
    $parentSlug: String
    $limit: Int
    $offset: Int
    $filter: BranchFilter
  ) {
    slug(slug: $slug, parentSlug: $parentSlug) {
      coupon {
        id
        brand {
          id
          name
          seoSlugUrl
          hasActivePromotion
          coverImages {
            imgMediumPath
          }
          logoImg {
            imgMediumPath
          }
          status {
            follow {
              count
              isInteract
            }
          }
        }
        branches(limit: $limit, offset: $offset, filter: $filter) {
          id
          nameTh
          nameEn
          title
          seoSlugUrl
          isNearBy
          zone {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_COUPON = gql`
  ${COUPON_CARD}
  query getCoupon($slug: String!, $parentSlug: String) {
    slug(slug: $slug, parentSlug: $parentSlug) {
      coupon {
        ...CouponCard
        image {
          imgPath
          imgAlt
        }
        isActive
        totalCoupons
        description
        redeemedAt
        redeemedTime {
          shortDateTime
        }
        statistics {
          numberOfBranches
        }
        updatedDate {
          iso
        }
        createdDate {
          iso
        }
        brand {
          id
          name
          seoSlugUrl
          isStore
          hasActivePromotion
          category {
            nameTh
            nameEn
          }
          coverImages {
            imgMediumPath
          }
          logoImg {
            imgMediumPath
          }
          status {
            follow {
              count
              isInteract
            }
          }
        }
        stores(limit: 30) {
          name
          seoSlugUrl
          logoImg {
            imgMediumPath
          }
          coverImages {
            imgMediumPath
          }
        }
      }
    }
  }
`;
