import { gql } from 'apollo-boost';

export const SET_READ_ALL = gql`
  mutation setReadAll($type: ReadAllType!) {
    readAll(type: $type) {
      successful
      errors {
        path
        message
      }
    }
  }
`;

export const SET_READ = gql`
  mutation setRead($id: String!) {
    read(id: $id, type: notification) {
      successful
      errors {
        path
        message
      }
    }
  }
`;

export const WRITE_MESSAGE = gql`
  mutation writeMessage($receiver: ReceiverInput!, $form: MessageForm!, $brandId: Int) {
    writeMessage(receiver: $receiver, form: $form, brandId: $brandId) {
      successful
      conversationSlug
      message {
        id
        sender {
          name
          profileImage {
            imgThumbPath
            imgAlt
          }
        }
        isMe
        description
        images {
          imgThumbPath
          imgAlt
        }
        source {
          __typename
          ... on Brand {
            id
            name
            logoImg {
              imgThumbPath
              imgAlt
            }
          }
          ... on Promotion {
            id
            title
          }
          ... on OrderPayment {
            _id:id
            status
            products (limit: 3) {
              product {
                images(limit: 1) {
                  imgThumbPath
                  imgAlt
                }
              }
            }
          }
          ... on CatalogProduct {
            _id:id
            name
            images(limit: 1) {
              imgThumbPath
              imgAlt
            }
          }
        }
        createdAt
        updatedAt
      }
      errors {
        message
        path
      }
    }
  }
`;

export const IS_CHAT_MANAGER_OF_BRAND = gql`
  mutation isChatManagerOfBrand($brandId: Int!) {
    isChatManagerOfBrand(brandId: $brandId) {
      successful
      errors {
        message
        path
      }
    }
  }
`;
