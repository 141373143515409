import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  input {
    transition: none;
  }
  ${(props) => props.error
    && `
  margin-bottom: 10px;
  input {
    margin-bottom: 0 !important;
    border: 2px solid #ed1c24 !important;
  }
`}
`;

const ErrorMessage = styled.span`
  color: #ed1c24;
  margin-top: 5px;
  display: block;
`;
const SuccessMessage = styled.span`
  color: #4caf50;
  margin-top: 5px;
  display: block;
`;
// TODO: ดัก input ตัวเลข
// const validate = (evt) => {
//   const theEvent = evt || window.event;
//   let key = theEvent.keyCode || theEvent.which;
//   key = String.fromCharCode(key);
//   const regex = /[0-9]|\./;
//   if (!regex.test(key)) {
//     theEvent.returnValue = false;
//     if (theEvent.preventDefault) theEvent.preventDefault();
//   }
// };

const TextInput = ({
  type, error, success, ...props
}) => {
  console.log('TextInput props: ', props);
  return (
    <Container error={!!error} style={props.containerStyle || {}}>
      <input
        type={type}
        {...props}
        onKeyPress={(e) => {
          if (props.onEnter && e.key === 'Enter') {
            props.onEnter();
          }
        }}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      {success && <SuccessMessage>{success.message}</SuccessMessage>}
    </Container>
  );
};

export default TextInput;
