import get from 'lodash/get';
import { ApolloClient } from 'apollo-client-preset';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { concat, split, ApolloLink } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { onError } from 'apollo-link-error';
import fetch from 'isomorphic-unfetch';
import { graphQLEndpoint, subScriptionEndpoint, storageKey } from '../config';
import introspectionQueryResultData from '../fragmentTypes.json';

global.fetch = fetch;
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

let apolloClient = null;

const create = (initialState) => {
  const httpLink = createHttpLink({
    uri: graphQLEndpoint,
    credentials: 'include',
  });

  const wsClient = new WebSocketLink(
    new SubscriptionClient(subScriptionEndpoint, {
      reconnect: true,
      lazy: true,
      connectionParams: () => ({
        token: localStorage.getItem(storageKey),
      }),
    }),
  );

  const hasSubscriptionOperation = ({ query: { definitions } }) => definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
  );

  const authLink = setContext((_, { headers }) => {
    // console.log('headers: ', headers);
    const token = localStorage.getItem(storageKey);
    return {
      headers: {
        ...headers,
        authorization: get(headers, 'authorization') || (token ? `${token}` : null),
        origin: 'https://proxumer.com',
        skipRefresh: true,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        return console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }
    if (networkError) {
      console.log('networkError: ', networkError);
    }
  });

  return new ApolloClient({
    link: split(
      hasSubscriptionOperation,
      wsClient,
      concat(authLink, ApolloLink.from([errorLink, httpLink])),
    ),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (object) => object.key || null,
    }).restore(initialState || {}),
  });
};

export default (initialState) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return create(initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }

  return apolloClient;
};
