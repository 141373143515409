import { useStoreState, useStoreActions } from 'easy-peasy';
import { get, find } from 'lodash';

import useMutation from './useMutation';
import { FOLLOW, UN_FOLLOW } from '../graphql/mutations/follow';

const useFollowBrand = ({
  objId, count = 0, isInteract, onFollowingDone = () => null,
}) => {
  const authenticated = useStoreState((state) => state.user.authenticated);
  const [followMutation] = useMutation(FOLLOW);
  const [unFollowMutation] = useMutation(UN_FOLLOW);

  const brandsFollowingStore = useStoreState((state) => state.follow.brands);
  const followBrand = useStoreActions((actions) => actions.follow.followBrand);
  const unFollowBrand = useStoreActions((actions) => actions.follow.unFollowBrand);
  const openModal = useStoreActions((actions) => actions.modal.openModal);

  const currentBrand = find(brandsFollowingStore, { id: objId });
  const toggleFollow = async () => {
    if (!authenticated) {
      openModal({
        type: 'LOGIN',
        action: {
          type: 'followBrand',
          objId,
        },
      });
      return;
    }
    try {
      if (get(currentBrand, 'isInteract', isInteract)) {
        const { data: unFollowResponse } = await unFollowMutation({
          variables: { id: objId, type: 'brand' },
        });
        if (
          unFollowResponse.unfollow.successful
          || get(unFollowResponse, 'unfollow.errors[0].message', '')
            === 'คุณได้ทำการบันทึกข้อมูลนี้ไปแล้วในระบบ'
        ) {
          unFollowBrand({ id: objId, isInteract: false, count: count - 1 });
        }
        console.log('unFollowResponse: ', unFollowResponse);
      } else {
        const { data: followResponse } = await followMutation({
          variables: { id: objId, type: 'brand' },
        });
        if (
          followResponse.follow.successful
          || get(followResponse, 'follow.errors[0].message', '')
            === 'คุณได้ทำการบันทึกข้อมูลนี้ไปแล้วในระบบ'
        ) {
          followBrand({ id: objId, isInteract: true, count: count + 1 });
          onFollowingDone();
        }
        console.log('followResponse: ', followResponse);
      }
    } catch (err) {
      console.log('err: ', err);
    }
  };
  const follow = async (brandId) => {
    try {
      const { data: followResponse } = await followMutation({
        variables: { id: brandId, type: 'brand' },
      });
      if (
        followResponse.follow.successful
        || get(followResponse, 'follow.errors[0].message', '')
          === 'คุณได้ทำการบันทึกข้อมูลนี้ไปแล้วในระบบ'
      ) {
        followBrand({ id: brandId, isInteract: true });
        onFollowingDone();
      }
      console.log('followResponse: ', followResponse);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  return {
    toggleFollow,
    follow,
    isInteract: get(currentBrand, 'isInteract', isInteract),
    count: get(currentBrand, 'count', count),
  };
};

export default useFollowBrand;
