import React from 'react';
import Modal from 'react-modal';
import { get } from 'lodash';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FollowButton from '../../buttons/follow-button';
import useFollowBrand from '../../../hooks/useFollowBrand';
import useCoupon from '../../../hooks/useCoupon';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const CouponFollowBrandModal = ({ coupon, isUse }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(400, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  const { claim, use } = useCoupon({
    coupon,
  });
  const { isInteract } = useFollowBrand({
    objId: get(coupon, 'brand.id', 0),
    isInteract: get(coupon, 'brand.status.follow.isInteract', false),
  });
  const brand = get(coupon, 'brand', {});
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div
          className="modal-dialog modal-md coupon brand-follow"
          style={{ margin: '0px', width: '100%' }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <img
                src={get(brand, 'coverImages[0].imgMediumPath', '')}
                alt=""
                className="brand-cover"
              />
              <div className="brand-cover-overlay" />
              <div className="brand-logo">
                <img src={get(brand, 'logoImg.imgMediumPath', '')} alt="" />
              </div>
            </div>
            <div className="modal-body">
              <h3>อย่าลืม!</h3>
              <h4>
                กดติดตาม
                {' '}
                <span>{get(brand, 'name', '')}</span>
                {` เพื่อ${isUse ? 'ใช้' : 'รับ'}คูปอง`}
              </h4>
              <FollowButton
                objId={get(brand, 'id', 0)}
                followStatus={get(brand, 'status.follow', {})}
                className="follow-button btn border curve on-card d-lg-block"
              />
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div
              className={`modal-footer ${isInteract ? '' : 'disabled'} ${
                isUse && isInteract ? 'received' : ''
              }`}
              onClick={() => {
                if (isInteract) {
                  if (isUse) {
                    use();
                  } else {
                    claim();
                  }
                } else {
                  return null;
                }
              }}
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (isInteract) {
                    if (isUse) {
                      use();
                    } else {
                      claim();
                    }
                  } else {
                    return null;
                  }
                }}
              >
                {isUse ? 'ใช้คูปอง' : 'รับคูปอง'}
              </a>
            </div>
          </div>
          <div />
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CouponFollowBrandModal;
