import { gql } from 'apollo-boost';

export const EDIT_PROFILE = gql`
  mutation editProfile(
    $username: String
    $password: String
    $name: String
    $email: String
    $tel: String
    $imgPath: String
    $imgAlt: String
    $imgFigure: String
    $gender: String
    $aboutMe: String
    $userSlug: String
    $address: String
    $province: String
    $district: String
    $subDistrict: String
    $zipcode: String
    $dob: Date
  ) {
    editProfile(
      username: $username
      password: $password
      name: $name
      email: $email
      tel: $tel
      imgPath: $imgPath
      imgAlt: $imgAlt
      imgFigure: $imgFigure
      gender: $gender
      aboutMe: $aboutMe
      userSlug: $userSlug
      address: $address
      province: $province
      district: $district
      subDistrict: $subDistrict
      zipcode: $zipcode
      dob: $dob
    ) {
      user {
        displayName
      }
      successful
      errors {
        path
        message
      }
    }
  }
`;
