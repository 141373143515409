import { action } from 'easy-peasy';
import { set } from 'lodash';

export default {
  version: '1.0.0',
  appLoading: false,
  isMobile: false,
  currentPage: '-',
  form: {},
  setFormData: action((state, { key, value }) => {
    set(state, `form.${key}`, value);
  }),
  setAppLoading: action((state, loading) => {
    state.appLoading = loading;
  }),
  setIsMobile: action((state, isMobile) => {
    state.isMobile = isMobile;
  }),
  setCurrentPage: action((state, currentPage) => {
    state.currentPage = currentPage;
  }),
};
