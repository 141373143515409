import React, { useState } from 'react';
import { get } from 'lodash';

import Countdown from '../../components/countdown-timer';
import useJoinCampaign from '../../hooks/useJoinCampaign';

const CampaignJoin = ({ data, loading }) => {
  const [expired, setExpired] = useState(false);
  const { join, getReward } = useJoinCampaign({
    campaignId: get(data, 'slug.brandCampaign.id', 0),
    status: get(data, 'slug.brandCampaign.status', {}),
    brandCampaign: get(data, 'slug.brandCampaign', {}),
    options: {
      isRequiredImage: get(data, 'slug.brandCampaign.isRequiredImage'),
    },
  });
  const endDate = get(data, 'slug.brandCampaign.endDate.iso', '');
  if (!data) return null;
  return (
    <div className="btn-wrapper">
      {!loading && get(data, 'slug.brandCampaign.isActive', false) && !expired ? (
        <div className="btn xl" onClick={join}>
          ร่วมกิจกรรม
        </div>
      ) : get(data, 'slug.brandCampaign.myReward.isGetReward', false)
        && !get(data, 'slug.brandCampaign.myReward.isCompleteForm', false) ? (
          <div className="btn xl reward" onClick={getReward}>
            รับรางวัลกิจกรรม
          </div>
        ) : !loading
        && !get(data, 'slug.brandCampaign.isActive', false)
        && !get(data, 'slug.brandCampaign.isExpire', false) ? (
          <div className="btn xl disable">เร็วๆนี้</div>
          ) : (
            <div className="btn xl disable">สิ้นสุดการร่วมกิจกรรมแล้ว</div>
          )}
      {!loading
      && !get(data, 'slug.brandCampaign.isActive', false)
      && !get(data, 'slug.brandCampaign.isExpire', false) ? null : (
        <Countdown date={`${endDate}`}>
          {({ countDown, addLeadingZeros }) => {
            // console.log('countDown: ', countDown);
            if (!countDown.sec && !countDown.min && !countDown.hours && !countDown.days) {
              if (countDown.end && !expired) setExpired(true);
              return null;
            }
            return (
              <>
                <h4>เหลือเวลาเข้าร่วม</h4>
                <div className="datetime">
                  <p>
                    {` ${addLeadingZeros(countDown.days)}`}
                    <span>วัน</span>
                    {' '}
                  </p>
                  :
                  <p>
                    {` ${addLeadingZeros(countDown.hours)}`}
                    <span>ชั่วโมง</span>
                    {' '}
                  </p>
                  :
                  <p>
                    {` ${addLeadingZeros(countDown.min)}`}
                    <span>นาที</span>
                    {' '}
                  </p>
                  :
                  <p>
                    {` ${addLeadingZeros(countDown.sec)}`}
                    <span>วินาที</span>
                    {' '}
                  </p>
                </div>
              </>
            );
          }}
        </Countdown>
        )}
    </div>
  );
};

export default CampaignJoin;
