/* eslint-disable */
import { gql } from 'apollo-boost';

export const COMMENT_CARD = gql`
  fragment CommentCard on Comment {
    id
    sections {
      __typename
      ... on TextSection {
        value
      }
      ... on User {
        id
        displayName
        userSlug
        profileImage {
          path(size: sq80)
          imgThumbPath
        }
        statistic {
          numberOfFollowedBrands
        }
      }
      ... on Brand {
        id
        name
        seoSlugUrl
        logoImg {
          path(size: sq80)
          imgThumbPath
        }
        coverImages {
          id
          imgSmallPath
        }
        status {
          follow {
            count
            isInteract
          }
        }
        numberOfFollower
      }
    }
    like {
      count
      isInteract
    }
    images {
      id
      imgPath
      imgThumbPath
      imgMediumPath
      imgAlt
    }
    isRoot
    rootComment {
      id
    }
    parent {
      id
      type
    }
    commenter {
      id
      displayName
      name
      email
      tel
      profileImage {
        id
        path(size: sq80)
        imgThumbPath
      }
      isStaff
      userSlug
    }
    likedUsers(limit: $likeUserLimit, offset: $likeUserOffset) {
      id
      tel
      name
      email
      userSlug
    }
    statistic {
      numberOfReplies
    }
    updatedAt
    createdAt
  }
`;
