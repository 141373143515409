import { useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { get, omitBy } from 'lodash';
import { REGISTER_REWARD } from '../graphql/mutations/campaign';
import useMutation from './useMutation';

const label = {
  validator: {
    empty_firstName: 'กรุณากรอกชื่อจริง',
    empty_lastName: 'กรุณากรอกนามสกุล',
    empty_gender: 'กรุณาระบุเพศ',
    empty_dob: 'กรุณาระบุวันเกิด',
    empty_tel: 'กรุณากรอกเบอร์โทร',
    empty_email: 'กรุณากรอกอีเมล',
    empty_address: 'กรุณากรอกรายละเอียดที่อยู่',
    empty_sub_district: 'กรุณากรอกตำบล/แขวง',
    empty_district: 'กรุณากรอกอำเภอ/เขต',
    empty_province: 'กรุณากรอกจังหวัด',
    empty_zipcode: 'กรุณากรอกรหัสไปรษณีย์',
  },
};

const useGetReward = ({ brandCampaignId }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [address, setAddress] = useState('');
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [subDistrict, setSubDistrict] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [registerReward] = useMutation(REGISTER_REWARD);
  const openToast = useStoreActions((actions) => actions.toast.openToast);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const submit = async () => {
    console.log('on submit', loading);
    if (loading) return;
    setLoading(true);
    if (
      firstName
      && lastName
      && email
      && tel
      && gender
      && dob
      && address
      && province
      && district
      && subDistrict
      && zipcode
    ) {
      const name = `${firstName} ${lastName}`;
      try {
        const { data } = await registerReward({
          variables: {
            brandCampaignId,
            name,
            email,
            tel: tel.replace(/-/g, ''),
            address,
            province,
            district,
            subDistrict,
            zipcode,
            note,
            gender,
            dob,
          },
          refetchQueries: ['getBrandCampaign'],
        });
        if (data.registerReward.successful) {
          openToast({
            message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
            timeout: 2000,
          });
          closeModal();
        } else if (!get(data, 'registerReward.errors[0].path')) {
          setErrorMessages({
            path: 'all',
            message: get(data, 'registerReward.errors[0].message'),
          });
        } else {
          setErrorMessages(get(data, 'registerReward.errors', []));
        }
        console.log('data: ', data);
      } catch (err) {
        console.log('err: ', err);
        setErrorMessages([{ path: 'all', message: err.message.replace(/GraphQL error:/g, '') }]);
      }
    } else {
      const errorLists = [
        { path: 'firstName', message: label.validator.empty_firstName },
        { path: 'lastName', message: label.validator.empty_lastName },
        { path: 'email', message: label.validator.empty_email },
        { path: 'tel', message: label.validator.empty_tel },
        { path: 'gender', message: label.validator.empty_gender },
        { path: 'dob', message: label.validator.empty_dob },
        { path: 'address', message: label.validator.empty_address },
        { path: 'province', message: label.validator.empty_province },
        { path: 'district', message: label.validator.empty_district },
        { path: 'subDistrict', message: label.validator.empty_sub_district },
        { path: 'zipcode', message: label.validator.empty_zipcode },
      ];
      const inputState = {
        firstName,
        lastName,
        email,
        tel,
        gender,
        dob,
        address,
        province,
        district,
        subDistrict,
        zipcode,
      };
      const errors = omitBy(errorLists, ({ path }) => get(inputState, path));
      console.log('errors: ', errors);
      setErrorMessages(errors);
    }
    setLoading(false);
  };

  return {
    firstName: { value: firstName, onChange: (e) => setFirstName(e.target.value) },
    lastName: { value: lastName, onChange: (e) => setLastName(e.target.value) },
    email: { value: email, onChange: (e) => setEmail(e.target.value) },
    tel: { value: tel, onChange: (e) => setTel(e.target.value) },
    gender: { value: gender, onChange: (e) => setGender(e.target.value) },
    dob: { value: dob, onChange: (value) => setDob(value) },
    address: { value: address, onChange: (e) => setAddress(e.target.value) },
    province: { value: province, onChange: (e) => setProvince(e.target.value) },
    district: { value: district, onChange: (e) => setDistrict(e.target.value) },
    subDistrict: { value: subDistrict, onChange: (e) => setSubDistrict(e.target.value) },
    zipcode: { value: zipcode, onChange: (e) => setZipcode(e.target.value) },
    note: { value: note, onChange: (e) => setNote(e.target.value) },
    errorMessages,
    loading,
    onSubmit: submit,
  };
};

export default useGetReward;
