import React, { useState } from 'react';
import {
  isEmpty, map, get, find, filter,
} from 'lodash';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { LoadingPureSpinner } from '../../components/loading';

import useQuery from '../../hooks/useQuery';
import { SUGGEST_CATEGORY } from '../../graphql/queries/user';

const List = styled.li`
  background: url(${({ bg }) => bg}) center center no-repeat !important;
  background-size: cover !important;
  ${({ current }) => current
    && `
    background: none !important;
    `};
`;

const PickCategory = () => {
  const history = useHistory();
  const [pickedCategory, setPickedCategory] = useState([]);
  const { data, loading } = useQuery(SUGGEST_CATEGORY);

  return (
    <div className="container verify-page select-cate full-height">
      <div className="card">
        <div className="header">
          <div className="ico">
            <img
              src="https://salehere.co.th/static/images/ico-brand-w.png"
              alt=""
              className="tall"
            />
          </div>
          <h3>
            เลือกหมวดที่คุณชื่นชอบ
            <span>เลือกได้มากกว่า 1 หมวด</span>
          </h3>
        </div>
        <div className="row">
          <div className="scroll-menu select-cate-menu">
            <ul className="menu-items" style={{ minHeight: '330px' }}>
              {loading ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <LoadingPureSpinner size={35} />
                </div>
              ) : (
                map(get(data, 'suggestCategories', []), (category) => {
                  const _current_ = !!find(pickedCategory, (c) => c.id === category.id);
                  return (
                    <List
                      key={`pick-category-${category.id}`}
                      bg={get(category, 'buttonImg.imgPath', '')}
                      current={_current_}
                      className={`col-4 ${_current_ ? 'current' : ''}`}
                      onClick={() => {
                        if (find(pickedCategory, (c) => c.id === category.id)) {
                          setPickedCategory(filter(pickedCategory, (c) => c.id !== category.id));
                        } else {
                          setPickedCategory([...pickedCategory, category]);
                        }
                      }}
                    >
                      <h5>{category.nameTh}</h5>
                      <h4>
                        <span>
                          <img
                            src={get(category, 'buttonImg.imgPath', '')}
                            alt={get(category, 'buttonImg.imgAlt', '')}
                            className="wide"
                          />
                        </span>
                        {category.nameTh}
                      </h4>
                    </List>
                  );
                })
              )}
            </ul>
          </div>
        </div>
        <div className="btn-wrapper text-right">
          <a
            href="#"
            className={`btn  ${isEmpty(pickedCategory) ? 'disabled' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              history.push('/pick-brand', { pickedCategory });
            }}
          >
            ถัดไป
            {' '}
            <i className="fa fa-angle-right" aria-hidden="true" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PickCategory;
