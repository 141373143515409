import React, { useState } from 'react';
import Modal from 'react-modal';
import { useStoreActions, useStoreState } from 'easy-peasy';
import InputMask from 'react-input-mask';

import TextInput from '../../form/text-input';
import { EDIT_PROFILE } from '../../../graphql/mutations/profile';
import useMutation from '../../../hooks/useMutation';
import { LoadingPureSpinner } from '../../loading';

import ModalContainer from '../container';
import { modalStyles } from '../styles';

const CouponEnterPhoneNoModal = ({ coupon }) => {
  const closeModal = useStoreActions((dispatch) => dispatch.modal.closeModal);
  const openModal = useStoreActions((dispatch) => dispatch.modal.openModal);
  const openToast = useStoreActions((actions) => actions.toast.openToast);

  const { isOpen } = useStoreState((state) => state.modal);
  const styles = modalStyles(400, {
    styles: {
      overlay: { alignItems: 'center', paddingTop: 0 },
      content: { overflow: 'visible', borderRadius: '12px' },
    },
  });
  const phoneNo = useStoreState((state) => state.coupon.phoneNo);
  const setPhoneNo = useStoreActions((actions) => actions.coupon.setPhoneNo);
  // const [phoneNo, setPhoneNo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const phoneNoReplaced = phoneNo.replace(/-/g, '');
  const validPhoneNo = phoneNoReplaced.length === 10;
  const [editProfileMutation] = useMutation(EDIT_PROFILE);
  const onSubmit = async () => {
    if (loading) {
      return;
    }
    if (!validPhoneNo) {
      return;
    }
    setLoading(true);
    try {
      const { data } = await editProfileMutation({
        variables: {
          tel: phoneNoReplaced,
        },
      });
      if (!data.editProfile.successful) {
        setErrorMessage(data.editProfile.errors[0].message);
      } else {
        console.log('verify now');
        openModal({
          type: 'COUPON_VERIFY_PHONE_NO',
          data: { phoneNo: phoneNoReplaced, coupon },
        });
      }
    } catch (err) {
      setErrorMessage(err.message);
      openToast({ message: err.message, timeout: 3000 });
    }
    setLoading(false);
  };
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      shouldCloseOnOverlayClick
      style={styles}
      closeTimeoutMS={330}
    >
      <ModalContainer
        noHeader
        closeModal={() => closeModal()}
        bodyStyles={{ background: 'transparent' }}
      >
        <div
          className="modal-dialog modal-md coupon enter-phone-no"
          style={{ margin: '0px', width: '100%' }}
        >
          <div className="modal-content">
            {loading && (
              <div className="loading-overlay">
                <LoadingPureSpinner />
              </div>
            )}
            <div className="modal-header">
              <div className="ico">
                <img
                  className="tall"
                  src="https://static.salehere.co.th/static/images/ico-verify-phone.png"
                  alt=""
                  title=""
                />
              </div>
              <div className="title">
                <span>ยืนยันเบอร์มือถือ</span>
                <span>เพื่อรับสิทธิพิเศษ</span>
              </div>
            </div>
            <div className="modal-body">
              <InputMask
                mask="999-999-9999"
                maskChar=""
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              >
                {(inputProps) => {
                  return <TextInput type="tel" {...inputProps} onEnter={onSubmit} autoFocus />;
                }}
              </InputMask>
              <span className={errorMessage ? 'has-error' : ''}>
                {errorMessage || 'กรอกเบอร์มือถือของคุณ'}
              </span>
              <div className="line-container">
                <div className="line" />
              </div>
            </div>
            <div className={`modal-footer ${validPhoneNo ? '' : 'disabled'}`} onClick={onSubmit}>
              <div>ยืนยัน</div>
            </div>
          </div>
          <div />
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default CouponEnterPhoneNoModal;
